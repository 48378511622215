import React, { useState } from "react";
import "./css/AddFile.css";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// components
import { useSnackbar } from "notistack";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import SendArrow from '../assets/top-arrow-btn.svg';
export default function AddComplianceJson() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [imageData, setImageData] = useState();
  const [showLoader, setshowLoader] = useState(false);
  const handleImageChange = async (e) => {
    setshowLoader(true);

    e.preventDefault();
    setImageData();
    setSelectedFileName(null);

    let files = e.target.files[0];
    console.log("files ", e);
    if (files) {

      let dataImage = new FormData();
      dataImage.append("jsonFile", e.target.files[0]);
      const checkImage = e.target.files[0];
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      const fileSize = Math.round(checkImage.size / 1024);
      if (fileExtension === "json") {
        if (fileSize <= 20000) {
          setImageData(dataImage);
          setSelectedFileName(e.target.files[0].name);
          setshowLoader(false);
        } else {
          enqueueSnackbar("Please upload less than 2 mb file.", {
            variant: "error",
          });
          setshowLoader(false);
        }
      }
      e.target.value = null;
    }
  };

  const do_upload = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        let endPoint = process.env.REACT_APP_UPLOAD_COMPLIANCE_AND_ISSUE;
        endPoint = "upload-compliance-and-issue";

        let temp = imageData;
        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          temp,
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            enqueueSnackbar("File uploaded successfully.", {
              variant: "success",
            });
            setshowLoader(false);
            setImageData();
            setSelectedFileName(null);
          } else {
            enqueueSnackbar("File not uploaded, some keys are missing", {
              variant: "error",
            });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar(uploadJson.data.message, { variant: "error" });
          setshowLoader(false);
        }
      }
    } catch (e) {
      enqueueSnackbar("Error in upload file", { variant: "error" });
      setshowLoader(false);
    }
  };

  // const handleMainTabChange = (event, newValue) => {
  //   setMainTabValue(newValue);
  //   setImageData(null)
  //   setSelectedFileName(null)
  // };

  return (
    <>
      <Loader show={showLoader} />
      {/* Comment-out the below code if you want to render addCompliance JSON compnent on click of sidebar  */}
      {/* <Helmet>
        <title> Dashboard: JSON FILE | Genesis Platform</title>
      </Helmet> */}
      {/* <Container maxWidth={false} className="max-w-left"> */}
      {/* <NewHeader titleName="Upload JSON" /> */}
      {/* <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}> */}
      <div>
        <Grid item xs={12} md={12} lg={12}>
          <div className="bgaddfiles">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography
                variant="h4"
                gutterBottom
                className="centerpoint"
              >
                <h2 className="heading-main ">
                  Select a Compliance JSON File
                </h2>
              </Typography>
            </Stack>
            <Card sx={{ p: 0, m: 0, width: "100%" }}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <form
                        onSubmit={(e) => {
                          do_upload(e);
                        }}
                        className="save-vendor-jsonfile"
                      >
                        <div>
                          <input
                            id="file-upload"
                            type="file"
                            accept=".json"
                            onChange={(e) => handleImageChange(e)}
                            style={{ display: "none" }}
                          />
                          <Button
                            htmlFor="file-upload"
                            size="small"
                            variant="contained"
                            color="primary"
                            component="label"
                            className="send-assessment Select-Json-File"
                          >
                            + Select Json File
                          </Button>
                        </div>
                        <label className="save-btn-block">
                          <b>
                            {selectedFileName !== null ? "File : " : null}
                          </b>
                          {selectedFileName !== null
                            ? selectedFileName
                            : null}
                        </label>

                        {selectedFileName !== null ? (
                          <LoadingButton
                            fullWidth
                            size="small"
                            type="submit"
                            variant="contained"
                            color="success"
                            className="send-assessment Select-Json-File savejson"
                            sx={{ width: "auto", color: "white" }}
                          >
                            <img src={SendArrow} className="send-arrow-icon" /> Save
                          </LoadingButton>
                        ) : null}
                      </form>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </div>
        </Grid>
      </div>
      {/* </Grid>
        </Grid> */}
      {/* </Container > */}
    </>
  );
}
