import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Grid,
} from "@mui/material";
import NewHeader from "../../layouts/dashboard/header/NewHeader";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import VulnerabilityManagementIssue from "./VulnerabilityManagementIssue.js";
import VulnerabilityManagementCVE from "./VulnerabilityManagementCVE.js";
import "../css/vulnerability.css";
import { useLocation, useNavigate } from "react-router-dom";

function VulnerabilityManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(location?.state?.mainTabValue || "1" );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    useEffect(() => {
      if (location.state?.mainTabValue !== undefined) {
        setValue(location.state.mainTabValue);
      }

      navigate(location.pathname, { replace: true });
    }, [location.state, navigate]);

  return (
    <>
      <Helmet>
        <title> Vulnerability | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Vulnerability" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>

          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TabContext value={value}>
              <Box className="first_tab">
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  allowScrollButtonsMobile
                  className="tab-main-heading margin-mui-tabfix"
                  aria-label="lab API tabs example"
                  sx={{ mt: 0 }}
                >
                  <Tab
                    label="Issue"
                    value="1"
                    className="active-color-remove second-mui"
                  />
                  <Tab
                    label="CVE"
                    value="2"
                    className="active-color-remove second-mui"
                  />
                </TabList>
                <TabPanel value="1">
                  <VulnerabilityManagementIssue />
                </TabPanel>
                <TabPanel value="2">
                  <VulnerabilityManagementCVE />
                </TabPanel>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default VulnerabilityManagement;
