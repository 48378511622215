import GetRequest from "../../components/apiConnections/getRequest";
import { transformInsideData, transformOutsideData, transformIndustryData } from "./TransformSunburstData";

export const getSunburstChartIndustryData = async () => {
  try {
    const endpoint = process.env.REACT_APP_GET_SUNBURST_CHART;

    const resDashboard = await GetRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`)
    const response = resDashboard?.data?.data;
    if (response) {
      return response;
    } else {
      console.error("No data received from Sunbrust API.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching Sunburst chart data:", error);
    return null;
  }
};

export const getInsideSunburstData = async (vendorUrl) => {
  try {
    if (vendorUrl) {
      // const endpoint = process.env.REACT_APP_SUNBURST_CHART_INSIDE_DETAILS;
      const endpoint = "get-sunburst-chart-inside-details-for-domain"
      const resDashboard = await GetRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?domain=${vendorUrl}`)
      const response = resDashboard?.data?.data?.data;
      if (response) {
        return response;
      } else {
        // console.error("No data received from Sunbrust API.");
        return null;
      }
    }
  } catch (error) {
    // console.error("Error fetching Sunburst chart data:", error);
    return null;
  }
};
export const getOutsideInSunburstData = async (vendorUrl) => {
  try {
    if (vendorUrl) {
      // const endpoint = process.env.REACT_APP_SUNBURST_CHART_OUTSIDE_DETAILS;
      const endpoint = "get-sunburst-chart-outside-details-for-domain"
      const resDashboard = await GetRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?domain=${vendorUrl}`)
      const response = resDashboard?.data?.data;
      if (response) {
        return response;
      } else {
        console.error("No data received from Sunbrust API.");
        return null;
      }
    }
  } catch (error) {
    console.error("Error fetching Sunburst chart data:", error);
    return null;
  }
};

export const getSunburstData = async () => {
  const response = await getSunburstChartIndustryData(); // Await the API call
  if (response) {
    const industry = transformIndustryData(response);


    for (const childrens of industry.children) {
      for (const subChildren of childrens.children) {
        for (const child of subChildren.children) {

          child.children = [];


          const insideData = await getInsideSunburstData(child.domain);
          if (insideData) {
            const transformInsideDatas = await transformInsideData(insideData);
            if(transformInsideDatas) child.children.push(transformInsideDatas) ; 
          }


          const outsideData = await getOutsideInSunburstData(child.domain);
          if (outsideData) {
            const transformOutsideDatas = await transformOutsideData(outsideData);
            if(transformOutsideDatas) child.children.push(transformOutsideDatas)
          }


        }
      }
    }

    return industry;
  }
};




