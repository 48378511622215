import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Container,
  styled,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Modal,
  Typography,
  InputLabel,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EyeMenu from "../../assets/eye-menu.svg";
import Trash from "../../assets/trahs.svg";
import CriticalImg from "../../assets/critical-image.svg";
import LowImage from '../../assets/low-img.svg';
import MediumImage from '../../assets/medium-img.svg';
import HighImage from '../../assets/high-img.svg';
import CrossImg from "../../assets/cross-select.svg";
import CVEDetail from "../VendorDetail/CveSideBar.js";
import { CustomNoRowsOverlay } from "../Assessment.js";
import PostRequest from "../../components/apiConnections/postRequest.js";
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import { useSnackbar } from "notistack";
import moment from "moment";
import GetRequest from "../../components/apiConnections/getRequest.js";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  background: '#120A3A',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  boxShadow: 24,
  maxHeight: "586px", // Set maximum height for scrolling
  overflowY: "auto",  // Enable vertical scrolling
};

function CveAdmin({
  patchingRowsData
}) {
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [selectedCVE, setselectedCVE] = useState({})
  const filteredRows =
    (!patchingRowsData || !patchingRowsData.length) ? [] :
      patchingRowsData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
        }));

  const columnsNew = [
    {
      field: "id",
      headerName: "#",
      flex: 0.5,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
      field: "CVE",
      headerName: "Name",
      flex: 1.1,
      minWidth: 170, // Ensure minimum width
      disableColumnMenu: true,
    },
    {
      field: "Category",
      headerName: "Category",
      flex: 1,
      minWidth: 125,
      disableColumnMenu: true,
    },
    {
      field: "Technology",
      headerName: "Technology",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params?.row?.ele?.Technology
      },
    },
    {
      field: "Severity",
      headerName: "Severity",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const severity = params.row.Severity ? params.row.Severity.toLowerCase() : "";
        let scoreTag = "-";
        if (severity === 'low') {
          scoreTag = "Low";
        } else if (severity === 'medium') {
          scoreTag = "Medium";
        } else if (severity === 'critical') {
          scoreTag = "Critical";
        } else if (severity === 'high') {
          scoreTag = "High";
        }
        // if (!severity) return "Loading...";
        if (!severity) return "-";
        return (
          scoreTag ? (
            <span className={`tagClassapproval blank-white ${scoreTag}`}>
              <span>{scoreTag}</span>
            </span>
          ) : (
            "-"
          )
        );
      },
    },


  ];
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const handleRowClick = (params) => {
    setselectedCVE(params?.row)
    setOpenSidebar(true)
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const uniqueIdGenerator = (contactPerson) => {
    // let firstName = ((contactPerson?.firstName)?.slice(0, 3))?.toUpperCase();
    // let createdBy = (localStorage.getItem("userEmail"))?.slice(0, 3)?.toUpperCase();
    // let category = (selectedCVE.category)?.slice(0, 3)?.toUpperCase();
    // let issueId=selectedCVE?.id

    // let timeStamp = new Date().getTime();
    // let uniqueId = `REM-${createdBy}-${firstName}-${category}-I${issueId}-${timeStamp}`;


    function generateSixDigitAlphanumeric() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#';
      let result = '';
      for (let i = 0; i < 6; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    }

    let alphaNumericId = generateSixDigitAlphanumeric();
    let uniqueId = `REM-${alphaNumericId}`;
    return uniqueId;
  }

  return (
    <>
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: "0px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
                value={paginationModel.pageSize}
                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>{" "}
          </div>
          <div className="issues-tab-table">
            <Box
              sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                  minWidth: "100%",
                  overflowX: "hidden",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minWidth: "100%",
                  overflowX: "auto",
                },
              }}
            >

              <DataGrid
                // loading={showLoader}
                rows={filteredRows}
                columns={columnsNew}
                // pageSize={pageSize}
                // pageSize={100}
                rowHeight={70}
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // rowsPerPageOptions={[5, 10, 20]}
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                components={{
                  NoRowsOverlay: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      No data
                    </Box>
                  ),
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  columns: ["id", "firstName", "lastName"],
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                paginationMode="client"
                disableSelectionOnClick
                autoHeight
                className="datagrid-table"
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%", // Column headers take up full width
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%", // Rows take up full width
                  },
                }}
                onRowClick={handleRowClick}
              />
            </Box>
          </div>
        </div>
      </Card>
      {openSidebar &&
        <CVEDetail
          handleClose={() => setOpenSidebar(false)}
          open={openSidebar}
          cveData={selectedCVE}
        />
      }
    </>
  );
}

export default CveAdmin;
