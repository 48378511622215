export const industryArray = [
  {
    "industryType": "Banking and Financial Services",
    "subIndustries": [
      "Retail Banking Solutions",
      "Payment Processors",
      "Financial Software (e.g., core banking systems, CRM)",
      "Credit Rating Agencies",
      "Financial Auditing Services",
      "Investment and Asset Management Software",
      "ATM Services and Equipment"
    ]
  },
  {
    "industryType": "Energy and Utilities",
    "subIndustries": [
      "Oil and Gas Equipment and Services",
      "Renewable Energy (Solar, Wind, etc.)",
      "Power Generation and Distribution Solutions",
      "Energy Grid Management Systems",
      "Smart Metering and IoT Providers",
      "Utility Billing and CRM Solutions",
      "Environmental Monitoring Services"
    ]
  },
  {
    "industryType": "Healthcare",
    "subIndustries": [
      "Medical Software Vendors (EMR, EHR systems)",
      "Medical Device Manufacturers",
      "Health Information Systems (HIS)",
      "Telemedicine Platforms",
      "Cloud-Based Health Solutions",
      "Pharmaceutical Supply Chain",
      "Compliance and Regulatory Solutions for Healthcare"
    ]
  },
  {
    "industryType": "Manufacturing and Industrial",
    "subIndustries": [
      "Industrial IoT Platforms",
      "Supply Chain and Logistics Software",
      "Robotics and Automation Solutions",
      "Industrial Equipment Manufacturers",
      "Manufacturing Execution Systems (MES)",
      "Predictive Maintenance Solutions",
      "Environmental Health and Safety (EHS) Compliance"
    ]
  },
  {
    "industryType": "Technology and Telecommunications",
    "subIndustries": [
      "Cloud Service Providers (IaaS, PaaS, SaaS)",
      "Software Development Vendors",
      "Data Centers and Hosting Services",
      "Cybersecurity Providers",
      "Telecommunications Services (Voice, Data, Network)",
      "Internet Service Providers (ISPs)",
      "API Providers and Integration Services"
    ]
  },
  {
    "industryType": "Retail and E-commerce",
    "subIndustries": [
      "E-commerce Platform Providers",
      "Payment Gateway and Processing Solutions",
      "Inventory Management Systems",
      "Point of Sale (POS) Systems",
      "Logistics and Delivery Services"
    ]
  },
  {
    "industryType": "Transportation and Logistics",
    "subIndustries": [
      "Fleet Management Systems",
      "Supply Chain Tracking and Management Software",
      "Shipping and Freight Services",
      "Warehousing and Storage Solutions",
      "Transportation Software (e.g., routing, logistics optimization)",
      "Delivery and Last-Mile Logistics Providers",
      "Customs and Compliance Services"
    ]
  },
  {
    "industryType": "Professional Services",
    "subIndustries": [
      "IT Consulting and Managed Services Providers",
      "Legal and Compliance Services",
      "Risk Management Consulting Firms",
      "Accounting and Financial Auditing Services",
      "Human Resources Outsourcing (HRO) Solutions",
      "Training and Development Providers (Cybersecurity, Software)"
    ]
  },
  {
    "industryType": "Construction and Real Estate",
    "subIndustries": [
      "Property Management Systems (PMS)",
      "Real Estate Software Solutions",
      "Construction Management Platforms",
      "Environmental Consulting Firms",
      "Construction Equipment Suppliers",
      "Building Information Modeling (BIM) Software",
      "Facilities Management Services"
    ]
  },
  {
    "industryType": "Digital Marketing and Advertising Tools",
    "subIndustries": [
      "Marketing Automation Platforms",
      "SEO and SEM Tools",
      "Social Media Management",
      "Content Management and Creation (CMS)",
      "Advertising Technology (AdTech)",
      "Analytics and Reporting"
    ]
  },
  {
    "industryType": "Aviation",
    "subIndustries": [
      "Airlines and Air Carriers",
      "Aircraft Maintenance, Repair, and Overhaul (MRO)",
      "Air Traffic Management Systems",
      "Aviation Fuel Suppliers",
      "Ground Handling Services",
      "In-flight Entertainment Systems",
      "Aviation Safety and Compliance Solutions",
      "Aircraft Leasing and Finance",
      "Cargo and Freight Airlines"
    ]
  }
]

export const industryColors = {
  // Banking and Financial Services
  "Banking and Financial Services": "#3066AD",
  "Retail Banking Solutions": "#4840E1",
  "Payment Processors": "#269AD0",
  "Financial Software (e.g., core banking systems, CRM)": "#7A75D1",
  "Credit Rating Agencies": "#327AAA",
  "Financial Auditing Services": "#2B8793",
  "Investment and Asset Management Software": "#86639F",
  "ATM Services and Equipment": "#798CAB",

  // Energy and Utilities
  "Energy and Utilities": "#30559F",
  "Oil and Gas Equipment and Services": "#1F6A72",
  "Renewable Energy (Solar, Wind, etc.)": "#38253A",
  "Power Generation and Distribution Solutions": "#38253A",
  "Energy Grid Management Systems": "#7C76E2",
  "Smart Metering and IoT Providers": "#7C76E2",
  "Utility Billing and CRM Solutions": "#7C76E2",
  "Environmental Monitoring Services": "#5A55B9",

  // Healthcare
  "Healthcare": "#D060A5",
  "Medical Software Vendors (EMR, EHR systems)": "#D06062",
  "Medical Device Manufacturers": "#445AFF91",
  "Health Information Systems (HIS)": "#4A7FB7",
  "Telemedicine Platforms": "#4A7FB7",
  "Cloud-Based Health Solutions": "#1D6F9D",
  "Pharmaceutical Supply Chain": "#006F8E",
  "Compliance and Regulatory Solutions for Healthcare": "#60A0D1",

  // Manufacturing and Industrial
  "Manufacturing and Industrial": "#285F6A",
  "Industrial IoT Platforms": "#285F6A",
  "Supply Chain and Logistics Software": "#63A776",
  "Robotics and Automation Solutions": "#39ADE3",
  "Industrial Equipment Manufacturers": "#39ADE3",
  "Manufacturing Execution Systems (MES)": "#3E35DB",
  "Predictive Maintenance Solutions": "#3E35DB",
  "Environmental Health and Safety (EHS) Compliance": "#3E35DB",

  // Technology and Telecommunications
  "Technology and Telecommunications": "#3E35DB",
  "Cloud Service Providers (IaaS, PaaS, SaaS)": "#3E35DB",
  "Software Development Vendors": "#236D8E",
  "Data Centers and Hosting Services": "#3066AD",
  "Cybersecurity Providers": "#4840E1",
  "Telecommunications Services (Voice, Data, Network)": "#269AD0",
  "Internet Service Providers (ISPs)": "#7A75D1",
  "API Providers and Integration Services": "#327AAA",

  // Retail and E-commerce
  "Retail and E-commerce": "#2B8793",
  "E-commerce Platform Providers": "#86639F",
  "Payment Gateway and Processing Solutions": "#798CAB",
  "Inventory Management Systems": "#30559F",
  "Point of Sale (POS) Systems": "#1F6A72",
  "Logistics and Delivery Services": "#38253A",

  // Transportation and Logistics
  "Transportation and Logistics": "#38253A",
  "Fleet Management Systems": "#7C76E2",
  "Supply Chain Tracking and Management Software": "#7C76E2",
  "Shipping and Freight Services": "#7C76E2",
  "Warehousing and Storage Solutions": "#5A55B9",
  "Transportation Software (e.g., routing, logistics optimization)": "#D060A5",
  "Delivery and Last-Mile Logistics Providers": "#D06062",
  "Customs and Compliance Services": "#445AFF91",

  // Professional Services
  "Professional Services": "#4A7FB7",
  "IT Consulting and Managed Services Providers": "#4A7FB7",
  "Legal and Compliance Services": "#1D6F9D",
  "Risk Management Consulting Firms": "#006F8E",
  "Accounting and Financial Auditing Services": "#60A0D1",
  "Human Resources Outsourcing (HRO) Solutions": "#285F6A",
  "Training and Development Providers (Cybersecurity, Software)": "#285F6A",

  // Construction and Real Estate
  "Construction and Real Estate": "#63A776",
  "Property Management Systems (PMS)": "#39ADE3",
  "Real Estate Software Solutions": "#39ADE3",
  "Construction Management Platforms": "#3E35DB",
  "Environmental Consulting Firms": "#3E35DB",
  "Construction Equipment Suppliers": "#3E35DB",
  "Building Information Modeling (BIM) Software": "#3E35DB",
  "Facilities Management Services": "#3E35DB",

  // Digital Marketing and Advertising Tools
  "Digital Marketing and Advertising Tools": "#3E35DB",
  "Marketing Automation Platforms": "#236D8E",
  "SEO and SEM Tools": "#3066AD",
  "Social Media Management": "#4840E1",
  "Content Management and Creation (CMS)": "#269AD0",
  "Advertising Technology (AdTech)": "#7A75D1",
  "Analytics and Reporting": "#327AAA",

  // Aviation
  "Aviation": "#2B8793",
  "Airlines and Air Carriers": "#86639F",
  "Aircraft Maintenance, Repair, and Overhaul (MRO)": "#798CAB",
  "Air Traffic Management Systems": "#30559F",
  "Aviation Fuel Suppliers": "#1F6A72",
  "Ground Handling Services": "#38253A",
  "In-flight Entertainment Systems": "#38253A",
  "Aviation Safety and Compliance Solutions": "#7C76E2",
  "Aircraft Leasing and Finance": "#7C76E2",
  "Cargo and Freight Airlines": "#7C76E2"
};

export const convertDataToSunburst = (rawData) => {
  // Map through the raw data and transform it into the sunburst format
  const sunburstData = {
    name: "root",
    children: industryArrayDummy.map(industry => {
      return {
        name: industry.name || "Industry",  // Use a default name in case it's undefined
        children: industry.subIndustries.map(subIndustry => {
          return {
            name: subIndustry.subIndustry,
            children: subIndustry.vendors.map(vendor => {
              return {
                name: vendor.name,
                size: 10, // You can replace 10 with a more meaningful value, like the number of products, revenue, etc.
                children: vendor.children // This can be empty initially and populated dynamically when clicked
              };
            })
          };
        })
      };
    })
  };

  return sunburstData;
};

export const industryArrayDummy = [
  {
    "subIndustries": [
      {
        "subIndustry": "Accounting and Financial Auditing Services",
        "vendors": [
          {
            name: "Nvidia",
            children: []
          },
          { name: "Honeywell International" }
        ],
        "vendorCount": 2
      },
    ],
    "subIndustryCount": 1,
    "industry": "Professional Services"
  },
  {
    "subIndustries": [
      {
        "subIndustry": "Telecommunications Services (Voice, Data, Network)",
        "vendors": [

          {
            name: "Qstream",
            children: []
          }
        ],
        "vendorCount": 1
      },
      {
        "subIndustry": "Software Development Vendors",
        "vendors": [
          {
            name: "Film Companion",
            children: []
          }
        ],
        "vendorCount": 1
      }
    ],
    "subIndustryCount": 1,
    "industry": "Technology and Telecommunications"
  }

]

export const industryArrayDummy2 = {
  name: "flare",
  children: [
    {
      name: "analytics",
      children: [
        {
          name: "cluster.com",
          children: [
            { name: "Agglomerative Cluster", value: 3938 },
            { name: "Community Structure", value: 3812 },
            { name: "Hierarchical Cluster", value: 6714 },
            { name: "Merge Edge", value: 743 },
          ],
        },
        {
          name: "graph",
          children: [
            { name: "BetweennessCentrality", value: 3534 },
            { name: "LinkDistance", value: 5731 },
            { name: "MaxFlowMinCut", value: 7840 },
            { name: "ShortestPaths", value: 5914 },
            { name: "SpanningTree", value: 3416 },
          ],
        },
      ],
    },
    {
      name: "animate",
      children: [
        {
          name: "EasingFunctions",
          children: [
            { name: "Agglomerative", value: 3938 },
            { name: "Community", value: 3812 },
            { name: "Hierarchical", value: 6714 },
            { name: "Merge", value: 743 },
          ],
        },
        {
          name: "PathAnimation",
          children: [
            { name: "Centrality", value: 3534 },
            { name: "Distance", value: 5731 },
            { name: "FlowMinCut", value: 7840 },
            { name: "Paths", value: 5914 },
            { name: "Spanning", value: 3416 },
          ],
        },
      ],
    },
  ],
};


export const industryArray3 = [
  {
    "subIndustries": [
      {
        "subIndustry": "Inventory Management Systems",
        "vendors": [
          {
            "name": "Google",
            "domain": "Google.com"
          }
        ],
        "vendorCount": 1
      }
    ],
    "subIndustryCount": 1,
    "industry": "Retail and E-commerce"
  },
  {
    "subIndustries": [
      {
        "subIndustry": "Software Development Vendors",
        "vendors": [
          {
            "name": "Film Companion",
            "domain": "Film Companion.com"
          }
        ],
        "vendorCount": 1
      },
      {
        "subIndustry": "Telecommunications Services (Voice, Data, Network)",
        "vendors": [
          {
            "name": "Qstream",
            "domain": "Qstream.com"
          }
        ],
        "vendorCount": 1
      }
    ],
    "subIndustryCount": 2,
    "industry": "Technology and Telecommunications"
  },
  {
    "subIndustries": [
      {
        "subIndustry": "Human Resources Outsourcing (HRO) Solutions",
        "vendors": [
          {
            "name": "SalesFuel",
            "domain": "SalesFuel.com"
          },
          {
            "name": "5Exceptions",
            "domain": "5exceptions.com"
          }
        ],
        "vendorCount": 2
      },
      {
        "subIndustry": "Accounting and Financial Auditing Services",
        "vendors": [
          {
            "name": "Nvidia",
            "domain": "Nvidia.com"
          },
          {
            "name": "Honeywell International",
            "domain": "Honeywell International.com"
          }
        ],
        "vendorCount": 2
      },
      {
        "subIndustry": "Training and Development Providers (Cybersecurity, Software)",
        "vendors": [
          {
            "name": "Tesla",
            "domain": "Tesla.com"
          }
        ],
        "vendorCount": 1
      }
    ],
    "subIndustryCount": 3,
    "industry": "Professional Services"
  },
  {
    "subIndustries": [
      {
        "subIndustry": "Real Estate Software Solutions",
        "vendors": [
          {
            "name": "Vidlet",
            "domain": "Vidlet.com"
          }
        ],
        "vendorCount": 1
      }
    ],
    "subIndustryCount": 1,
    "industry": "Construction and Real Estate"
  },
  {
    "subIndustries": [
      {
        "subIndustry": "Renewable Energy (Solar, Wind, etc.)",
        "vendors": [
          {
            "name": "Boeing",
            "domain": "Boeing.com"
          },
          {
            "name": "Magna International",
            "domain": "Magna International.com"
          }
        ],
        "vendorCount": 2
      }
    ],
    "subIndustryCount": 1,
    "industry": "Energy and Utilities"
  },
  {
    "subIndustries": [
      {
        "subIndustry": "Social Media Management",
        "vendors": [
          {
            "name": "Filmibeat",
            "domain": "Filmibeat.com"
          }
        ],
        "vendorCount": 1
      }
    ],
    "subIndustryCount": 1,
    "industry": "Digital Marketing and Advertising Tools"
  }
]