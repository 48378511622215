import React, { useEffect, useState } from "react";
import SearchIcon from "../../assets/searchico.svg";
import {
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Box,
  InputAdornment,
  Typography,
  IconButton,
  Menu,
  Modal,
  Grid
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// import UploadIcon from "@mui/icons-material/Upload";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import PostRequest from "../../components/apiConnections/postRequest";
import GetRequest from "../../components/apiConnections/getRequest";
import PutRequest from "../../components/apiConnections/putRequest";
import { useSnackbar } from "notistack";
import { CustomNoRowsOverlay } from "../Assessment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EyeMenu from "../../assets/eye-menu.svg";
import UploadIcon from '../../assets/file-upload-line.svg';
import CloseIcon from '@mui/icons-material';
import CrossPop from '../../assets/pop-cross.svg';

function UploadDocuments() {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadList, setUploadList] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [filterSearch, setFilterSearch] = useState(null)
  const [activeDocument, setActiveDocument] = useState({
    documentId: "",
    documentName: "",
    file: null,
  })
  const [showUpdateDocumentModal, setShowUpdateDocumentModal] = useState(false)
  const [disableUploadButton, setDisableUploadButton] = useState(false)
  function RowMenu({ handleView, handleUpdate }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick} className='three-dots-btn'>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className='rowmenu'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleView} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
          {/* <MenuItem onClick={handleUpdate} className='border-bot-line'> <img src={UploadIcon} /> Update</MenuItem> */}
        </Menu>
      </>
    );
  }

  const columns = [
    { field: "id", flex: 0.5, headerName: "S. No", width: 100 },
    { field: "documentName", flex: 1, headerName: "Document Name", width: 200 },
    { field: "clientName", flex: 0.8, headerName: "Client Name", width: 130 },
    { field: "clientEmail", flex: 1, headerName: "Client Email", width: 130 },
    { field: "uploadedBy", flex: 1, headerName: "Uploaded By", width: 130 },
    { field: "updatedAt", flex: 0.7, headerName: "Last Uploaded", width: 100 },
    {
      field: "action", flex: 0.5, headerName: "Action", width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <RowMenu
            handleView={() => {
              const fileUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${params.row.fileName}`;
              window.open(fileUrl, '_blank', 'noopener,noreferrer');
            }}
            handleUpdate={() => {
              setActiveDocument({
                documentId: params.row._id,
                documentName: params.row.documentName,
                file: null
              })
              setShowUpdateDocumentModal(true)
            }}
          />

        );
      },

    }
  ];

  useEffect(() => {
    getUploadDocuments(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (filterSearch !== null) getUploadDocuments(1, paginationModel.pageSize)
    }, 500)
    return () => clearTimeout(time)
  }, [filterSearch])

  const getUploadDocuments = async (page, limit) => {
    setShowLoader(true);
    setUploadList([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_UPLOADED_DOCUMENTS_BY_VENDOR}?page=${page
          }&limit=${limit}&search=${filterSearch || ""}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setRowCount(arrResponse?.total || 0);
            if (Array.isArray(arrResponse?.data)) setUploadList(
              arrResponse?.data?.length
                ? [
                  ...arrResponse?.data?.map((d, i) => ({
                    ...d,
                    id: ((page - 1) * limit) + (i + 1),
                    fileName: d.documentName || "",
                    clientName: d.clientDetail?.firstName || "",
                    clientEmail: d.clientDetail?.emailId,
                    documentName: d.documentDetail?.name || "",
                    uploadedBy: d.uploadedBy?.emailId || "",
                    updatedAt: moment(d.updatedAt)?.format("DD-MMM-YYYY")
                  })),
                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errVendor) => {
            // enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  }

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleSearch = (event) => {
    setFilterSearch(event.target.value)
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type === "application/pdf") {
      setActiveDocument({ ...activeDocument, file });
    } else {
      enqueueSnackbar("Please upload a valid PDF file.", { variant: "error" });
    }
    e.target.value = null;
  }

  const updateDocumentHandler = async (e) => {
    e.preventDefault();
    if (!activeDocument.file) {
      enqueueSnackbar("Please upload valid PDF file.", {
        variant: "error",
      });
      return;
    }
    setDisableUploadButton(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        let documentFormData = new FormData();
        documentFormData.append("documentId", activeDocument.documentId)
        documentFormData.append("documentOriginalName", activeDocument.documentName)
        documentFormData.append("vendorDocumentFiles", activeDocument.file)
        const updateDocument = await PutRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_DOCUMENT_BY_VENDOR}`,
          documentFormData,
          ""
        );
        if (updateDocument && updateDocument.status === 200) {
          if (updateDocument.data.code === 200) {
            enqueueSnackbar("Document updated successfully.", {
              variant: "success",
            });
            setDisableUploadButton(false);
            setActiveDocument({});
            setShowUpdateDocumentModal(false);
            getUploadDocuments(paginationModel.page + 1, paginationModel.pageSize)
          } else {
            enqueueSnackbar(updateDocument?.message || updateDocument.data?.message, {
              variant: "error",
            });
            setDisableUploadButton(false);
          }
        } else {
          enqueueSnackbar(updateDocument.data.message, { variant: "error" });
          setDisableUploadButton(false);
        }
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar("Error while updating file", { variant: "error" });
      setDisableUploadButton(false);
    }
  }

  const updateDocumentModalContent = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      bgcolor: "#150d43",
      boxShadow: 24,
      textAlign: "center",
      borderRadius: "15px",
      p: 4,
    };
    return (
      <Modal
        open={true}
        onClose={() => setShowUpdateDocumentModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${"blur-background"}`}
      >
        <div>
          <Box
            sx={style}
          >
            <Box
              className="add-new-attack-heading"
            >
              <p>Update Document</p>
              <img
                onClick={() => setShowUpdateDocumentModal(false)}
                src={CrossPop}
                style={{ cursor: "pointer" }}
                alt="icon"
              />
            </Box>
            <Box sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              pt: 3,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px"
            }}
            // className="attackpart"
            >
              <input
                id="file-upload"
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e)}
                style={{ display: "none" }}
              />
              <Button
                htmlFor="file-upload"
                size="small"
                variant="contained"
                color="primary"
                component="label"
                className="select-jeson"
              >
                Select Document
              </Button>
              <label className="save-btn-block">
                <b>
                  {activeDocument.file !== null ? `File : ` : null}
                </b>
                {activeDocument.file !== null
                  ? activeDocument.file?.name
                  : null}
              </label>

              {activeDocument.file !== null ? (
                <LoadingButton
                  htmlFor="file-upload"
                  size="small"
                  variant="contained"
                  color="primary"
                  component="label"
                  className="select-jeson"
                  loading={disableUploadButton}
                  onClick={(e) => updateDocumentHandler(e)}
                  disabled={disableUploadButton}
                >
                  Proceed
                </LoadingButton>
              ) : null}
            </Box>
          </Box>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "6px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
              // value={paginationModel.pageSize}
              // onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>
          </div>
          <Box
            sx={{
              width: "100%",
              "& .MuiDataGrid-columnHeaders": {
                minWidth: "100%",
                overflowX: "hidden",
              },
              "& .MuiDataGrid-virtualScroller": {
                minWidth: "100%",
                overflowX: "auto",
              },
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search…"
              value={filterSearch}
              onChange={handleSearch}
              className="searh-text-field"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="icon" />
                  </InputAdornment>
                ),
              }}
            />
            <DataGrid
              loading={showLoader}
              sx={{ border: "none", height: "409px", width: "100%" }}
              rows={uploadList}
              rowCount={rowCount}
              columns={columns}
              // pageSize={pageSize}
              rowHeight={70}
              paginationMode="server"
              rowsPerPageOptions={[5, 10, 20]}
              onPaginationModelChange={handlePaginationModelChange}
              paginationModel={paginationModel}
              disableSelectionOnClick
              autoHeight
              className="datagrid-table"
              autosizeOptions={{
                includeOutliers: true,
                includeHeaders: false,
              }}
              pageSizeOptions={[5, 10, 20]}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
            />
          </Box>
        </div>
      </Card>
      {showUpdateDocumentModal ? updateDocumentModalContent() : null}
    </>
  );
}

export default UploadDocuments;
