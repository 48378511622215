import React, { useEffect, useState } from "react";
import {
  Card,
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  InputAdornment,
  IconButton,
  Stack,
  FormControl, Container
} from "@mui/material";
import UserProfile from "./../../assets/user-profile.svg";
import { useSnackbar } from "notistack";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { LoadingButton } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import '../css/subscription.css';
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import moment from "moment";

export const textFieldStyles = {
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
  ".MuiInputBase-root": {
    borderRadius: "20px", // Adjust the border radius as needed
  },
  ".MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "20px", // Adjust the border radius as needed
    },
  },
};

const Subscriptions = ({ props, stepper, origin, handleClose, loader }) => {
  const addClientDetails = props.addClientDetails;
  const changeActiveComponent = props.changeActiveComponent;
  const [formData, setFormData] = useState(props.client);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (formData.websites) {
      setFormData({ ...formData, numberOfWebsites: Math.max(formData.numberOfWebsites, formData.websites.length) });
    }
  }, [formData.websites]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = Math.max(0, value);
    if(name === "numberOfWebsites" && newValue < formData?.websites?.length ){
      enqueueSnackbar(
        `Number of websites should not be less than ${formData.websites.length}`,
        { variant: "warning" }
      );
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleDecrease = (name) => {
    setFormData((prevState) => {
      const newValue = Math.max((parseInt(prevState[name]) || 0) - 1, 0);
      if (name === "numberOfWebsites" && newValue < formData.websites.length) {
        enqueueSnackbar(
          `Number of websites should not be less than ${formData.websites.length}`,
          { variant: "warning" }
        );
        return prevState;
      }
      return {
        ...prevState,
        [name]: newValue,
      };
    });
  };

  const handleIncrease = (name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: (parseInt(prevState[name]) || 0) + 1,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked ? 1 : 0,
    }));
  };

  const checkValidation = () => {
    // const requiredFields = {
    //   numberOfVendors: "Number Of Vendors is required",
    //   numberOfUsers: "Number Of Users is required",
    //   numberOfWebsites: "Number Of Websites is required",
    //   numberOfQuestionniare: "Number Of Questionniare is required",
    //   numberOfAttackRequests: "Number Of Attack Requests is required",
    //   numberOfAssessments: "Number Of Assessments is required",
    // };
    // for (const field of Object.keys(requiredFields)) {
    //   if (!formData[field]) {
    //     enqueueSnackbar(requiredFields[field], { variant: "warning" });
    //     return false;
    //   }
    // }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      addClientDetails(formData);
      changeActiveComponent(3);
    }
  };

  return (
    <>
      <Container maxWidth={false} className="max-w-left">
        {origin !== "clientDetail" ?
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="d-flex-company-info">
                <div className="d-flex-company-logo">
                  <img
                    src={
                      typeof formData.logo === "string"
                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.logo}`
                        : formData.logo
                          ? URL.createObjectURL(formData.logo)
                          : UserProfile
                    }
                    alt="login"
                    style={{
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                </div>
                <div className="align-peragraph-p">
                  <h3>{formData.company}</h3>
                  <p>
                    <PermIdentityIcon />{" "}
                    {`${formData.firstName} ${formData.lastName}`}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid> : null}
        {origin !== "clientDetail" ?
          <Box className="mbtop-manage" sx={{ mt: 4 }}>
            <Typography variant="h4">
              <span className="heading-main">Subscriptions</span>
            </Typography>
          </Box> : null}

        <form
          className="subscription-form"
          autoComplete="off"
          onSubmit={(e) => {
            onSubmit(e);
          }}
        >
          <Card sx={{ mt: 1, mb: 5, width: origin !== "clientDetail" ? "600px" : null }}
            className={origin == "clientDetail" ? "subscription-origin-client-detail" : null}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              {!formData?.clientId && stepper}
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2} className="align-middle-parts">
                  <Grid item xs={4} md={2} lg={2}>
                    <h5 className="sub-heading-vendor">Deadline</h5>
                  </Grid>
                  <Grid item xs={8} md={4} lg={4} sx={{ pr: "52px" }}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        className='add-vender-input-design'
                        type="date"
                        name="deadline"
                        // InputLabelProps={{ shrink: true, required: true }}
                        value={formData.deadline || ""}
                        title="Select Deadline"
                        // label="Deadline"
                        onChange={(e) => setFormData({ ...formData, deadline: e.target.value })}
                        inputProps={{
                          min: moment().format('YYYY-MM-DD'),
                          onKeyDown: (e) => e.preventDefault(),
                          style: {
                            WebkitAppearance: 'none',
                            MozAppearance: 'textfield',
                            appearance: 'none',
                            position: 'relative',
                          },
                        }}
                        sx={{ color: "white" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={CalendarTodayIcon}
                                alt="calendar icon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  document.getElementsByName('deadline')[0]?.showPicker();
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={5.5}>
                <Grid container spacing={2} className="align-middle-parts">
                  <Grid item xs={6} md={9} lg={9}>
                    <h5 className="sub-heading-vendor">Number Of Vendors</h5>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <TextField
                      type="number"
                      size="small"
                      name="numberOfVendors"
                      value={formData.numberOfVendors > -1 ? parseInt(formData.numberOfVendors, 10) : ''}
                      className="quantity-field-input"
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="border-left-right"
                            >
                              <IconButton
                                size="small"
                                className="quantity-btn border-bottom-btns"
                                onClick={() => handleIncrease("numberOfVendors")}
                              >
                                <ArrowDropUpIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                className="quantity-btn"
                                onClick={() => handleDecrease("numberOfVendors")}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            </div>
                          </InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 100000,
                        },
                      }}
                      sx={textFieldStyles}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={1}></Grid>
              <Grid item xs={12} md={12} lg={5.5}>
                <Grid container spacing={2} className="align-middle-parts">
                  <Grid item xs={6} md={9} lg={9}>
                    <h5 className="sub-heading-vendor">
                      Number Of Attack Requests
                    </h5>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className="d-flex-checkbox">
                      <Checkbox
                        checked={!!formData.isAttackRequestEnabled}
                        onChange={handleCheckboxChange}
                        name="isAttackRequestEnabled"
                        color="primary"
                        className="absolute-checkbox"
                      />
                      <TextField
                        type="number"
                        size="small"
                        name="numberOfAttackRequests"
                        className="quantity-field-input"
                        value={formData.numberOfAttackRequests > -1 ? parseInt(formData.numberOfAttackRequests, 10) : ''}
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="border-left-right"
                              >
                                <IconButton
                                  size="small"
                                  className="quantity-btn border-bottom-btns"
                                  onClick={() =>
                                    handleIncrease("numberOfAttackRequests")
                                  }
                                >
                                  <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  className="quantity-btn"
                                  onClick={() =>
                                    handleDecrease("numberOfAttackRequests")
                                  }
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </div>
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            max: 10000,
                          },
                        }}
                        sx={textFieldStyles}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} lg={5.5}>
                <Grid container spacing={2} className="align-middle-parts">
                  <Grid item xs={6} md={9} lg={9}>
                    <h5 className="sub-heading-vendor">Number Of Websites</h5>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <TextField
                      type="number"
                      size="small"
                      name="numberOfWebsites"
                      value={formData.numberOfWebsites > -1 ? parseInt(formData.numberOfWebsites, 10) : ''}
                      className="quantity-field-input"
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="border-left-right"
                            >
                              <IconButton
                                size="small"
                                className="quantity-btn border-bottom-btns"
                                onClick={() => handleIncrease("numberOfWebsites")}
                              >
                                <ArrowDropUpIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                className="quantity-btn"
                                onClick={() => handleDecrease("numberOfWebsites")}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            </div>
                          </InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 10000,
                        },
                      }}
                      sx={textFieldStyles}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={1}></Grid>
              <Grid item xs={12} md={12} lg={5.5}>
                <Grid container spacing={2} className="align-middle-parts">
                  <Grid item xs={6} md={9} lg={9}>
                    <h5 className="sub-heading-vendor">
                      Number Of Questionnaires
                    </h5>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className="d-flex-checkbox">
                      <Checkbox
                        checked={!!formData.isQuestionniareEnabled}
                        onChange={handleCheckboxChange}
                        name="isQuestionniareEnabled"
                        color="primary"
                        className="absolute-checkbox"
                      />
                      <TextField
                        type="number"
                        size="small"
                        name="numberOfQuestionniare"
                        className="quantity-field-input"
                        value={formData.numberOfQuestionniare > -1 ? parseInt(formData.numberOfQuestionniare, 10) : ''}
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="border-left-right"
                              >
                                <IconButton
                                  size="small"
                                  className="quantity-btn border-bottom-btns"
                                  onClick={() =>
                                    handleIncrease("numberOfQuestionniare")
                                  }
                                >
                                  <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  className="quantity-btn"
                                  onClick={() =>
                                    handleDecrease("numberOfQuestionniare")
                                  }
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </div>
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            max: 10000,
                          },
                        }}
                        sx={textFieldStyles}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} lg={5.5}>
                <Grid container spacing={2} className="align-middle-parts">
                  <Grid item xs={6} md={9} lg={9}>
                    <h5 className="sub-heading-vendor">Number Of Users</h5>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <TextField
                      type="number"
                      size="small"
                      name="numberOfUsers"
                      value={formData.numberOfUsers > -1 ? parseInt(formData.numberOfUsers, 10) : ''}
                      className="quantity-field-input"
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="border-left-right"
                            >
                              <IconButton
                                size="small"
                                className="quantity-btn border-bottom-btns"
                                onClick={() => handleIncrease("numberOfUsers")}
                              >
                                <ArrowDropUpIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                className="quantity-btn"
                                onClick={() => handleDecrease("numberOfUsers")}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            </div>
                          </InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 10000,
                        },
                      }}
                      sx={textFieldStyles}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={1}></Grid>
              <Grid item xs={12} md={12} lg={5.5}>
                <Grid container spacing={2} className="align-middle-parts">
                  <Grid item xs={6} md={9} lg={9}>
                    <h5 className="sub-heading-vendor">Number Of Assessments</h5>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className="d-flex-checkbox">
                      <Checkbox
                        checked={!!formData.isAssessmentEnabled}
                        onChange={handleCheckboxChange}
                        name="isAssessmentEnabled"
                        color="primary"
                        className="absolute-checkbox"
                      />
                      <TextField
                        type="number"
                        size="small"
                        name="numberOfAssessments"
                        className="quantity-field-input"
                        value={formData.numberOfAssessments > -1 ? parseInt(formData.numberOfAssessments, 10) : ''}
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="border-left-right"
                              >
                                <IconButton
                                  size="small"
                                  className="quantity-btn border-bottom-btns"
                                  onClick={() =>
                                    handleIncrease("numberOfAssessments")
                                  }
                                >
                                  <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  className="quantity-btn"
                                  onClick={() =>
                                    handleDecrease("numberOfAssessments")
                                  }
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </div>
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            max: 10000,
                          },
                        }}
                        sx={textFieldStyles}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {origin !== "clientDetail" ? <div className="d-flex-next-btn">
              <LoadingButton
                size="medium"
                // type="submit"
                variant="contained"
                color="success"
                className="upload-new rightside"
                onClick={() => {
                  addClientDetails(formData);
                  changeActiveComponent(1);
                }}
              >
                Back
              </LoadingButton>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                color="success"
                className="upload-new rightside"
              >
                Next
              </LoadingButton>
            </div> :
              <div className="d-flex-next-btn">
                {/* <LoadingButton
                size="medium"
                variant="contained"
                className="upload-new rightside"
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </LoadingButton> */}
                <LoadingButton
                  loading={loader}
                  size="medium"
                  variant="contained"
                  color="success"
                  className="upload-new rightside"
                  onClick={() => addClientDetails(formData)}
                >
                  {!loader ? " Submit" : null}
                </LoadingButton>
              </div>}
          </Card>
        </form>
      </Container>
    </>
  );
};

export default Subscriptions;
