import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SvgColor from "../../../components/svg-color";
// @mui
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled, alpha } from '@mui/material/styles';
import { Box, List, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import Navigation from './config';
import './sidebar.css';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

const NAV_WIDTH = 236;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 0,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, toggleTheme }) {
  let selector = useSelector((state) => state.permissionList.permissionList || []);
  let icuId = parseInt(window.localStorage.getItem("icu"));
  // console.log("selector 423",selector);
  const { pathname } = useLocation();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [roleId, setroleId] = useState(parseInt(window.localStorage.getItem('roleId')));
  const { navConfig, navConfigAdmin, navConfigVendor } = Navigation();
  let activeSideNav = [];

  if (parseInt(window.localStorage.getItem('roleId')) == 1) {
    // activeSideNav = navConfig;
    // activeSideNav = activeSideNav.filter(item => item.isVisible==true);
  } else if (parseInt(window.localStorage.getItem('roleId')) == 2) {
    activeSideNav = navConfigAdmin
  } else if (parseInt(window.localStorage.getItem('roleId')) == 3) {
    activeSideNav = navConfigVendor;
  }
  const [navData, setNavData] = useState(activeSideNav);

  const icon = (name) => (
    <SvgColor
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1 }}
    />
  );

  useEffect(() => {
    if (parseInt(window.localStorage.getItem('roleId')) == 1) {
      // activeSideNav = navConfig;
      // console.log("selector 123",selector);

      const checkPermission = (value) => {
        // console.log("value",value);
        if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
          return true;
        }
        else if (selector?.includes(value)) return true;
        else return false;
        // return Array.isArray(selector) && selector.includes(value);
      }

      const navConfigTest = [
        {
          title: "Dashboard",
          path: "/dashboard/app",
          icon: icon("dashboard"),
          isVisible: checkPermission("Dashboard") ? true : false,
        },
        {
          title: "Ecosystem",
          path: "/dashboard/vendors",
          icon: icon("ecosystem"),
          isVisible: checkPermission("Ecosystem") ? true : false,
        },
        {
          title: "Attack Surface",
          path: "/dashboard/attacks",
          icon: icon("attack-surface"),
          isVisible: checkPermission("Attack Surface") ? true : false,
        },
        {
          title: "Questionnaire",
          path: "/dashboard/questionnaire-library",
          icon: icon("questionnaire"),
          isVisible: checkPermission("Questionnaire") ? true : false,
        },
        {
          title: "Assessment",
          path: "/dashboard/assessment",
          icon: icon("assesment"),
          isVisible: checkPermission("Assessment") ? true : false,
        },
        {
          title: "chat",
          path: "/dashboard/clientChat",
          icon: icon("chat"),
          isVisible: checkPermission("Chat") ? true : false,
        },
        {
          title: "My Account",
          path: "/dashboard/my-account",
          icon: icon("peoples"),
          isVisible: icuId !== 1 ? true : false,
        },
        {
          title: "Vulnerability",
          path: "/dashboard/vulnerability",
          icon: icon("vulnerability-icon"),
          isVisible: true,
        },
      ];
      // console.log("navConfigTest",navConfigTest);
      setNavData([...navConfigTest.filter(item => item.isVisible == true)]);
    }
  }, [selector])

  const isDesktop = useResponsive('up', 'lg');
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const themeName = localStorage.getItem('theme');
    setTheme(themeName);
  }, [])

  const toggleModeButtonClick = () => {
    setTheme((theme === 'Dark') ? `Light` : `Dark`)
    toggleTheme();
  }
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box className="logo-sidebar">
        <Logo />
      </Box>

      <div className={(theme == 'Dark') ? 'dark-mode-sidebar' : 'light-mode-class'}>
        <div className='navBar'>
          <NavSection data={navData} />
          <div className='section-bottom'>
            <Box style={{ width: '100%' }} >
              {/* <Link underline="none" className='username-profile'>
                <StyledAccount>
                  <Avatar src={account.photoURL} alt="photoURL" />
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                      {account.displayName}
                    </Typography>
                  </Box>
                </StyledAccount>
              </Link> */}
              {/* <div className='toggleMode' style={{ marginTop: '5px' }}>
                <FormControlLabel control={<Switch defaultChecked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} />
              </div> */}
            </Box>
          </div>
        </div>
      </div>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          <Scrollbar
            sx={{
              height: 1,
              '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
          >
            <Box className="logo-sidebar">
              <Logo />
            </Box>

            <div className={(theme == 'Dark') ? 'dark-mode-sidebar' : 'light-mode-class'}>
              <div className='navBar'>
                <NavSection data={navData} />
                <div className='section-bottom'>
                  <Box style={{ width: '100%' }} >
                    <Link underline="none" className='username-profile'>
                      <StyledAccount>
                      <Avatar src={account?.companyDetail?.url
                          ? `${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${account?.companyDetail?.url}` : account.photoURL} alt="photoURL" />
                        <Box sx={{ ml: 2 }}>
                          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {account.companyDetail?.name || account.displayName}
                          </Typography>
                        </Box>
                      </StyledAccount>
                    </Link>
                    {/* <div className='toggleMode' style={{ marginTop: '5px' }}>
                <FormControlLabel control={<Switch defaultChecked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} />
              </div> */}
                  </Box>
                </div>
              </div>
            </div>
          </Scrollbar>
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          className='desktop-hide-nav'
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          <Scrollbar
            sx={{
              height: 1,
              '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
          >
            <Box className="logo-sidebar">
              <Logo />
            </Box>

            <div className={(theme == 'Dark') ? 'dark-mode-sidebar' : 'light-mode-class'}>
              <div className='navBar'>
                <NavSection data={navData} />
                <div className='section-bottom'>
                  <Box style={{ width: '100%' }} >
                    <Link underline="none" className='username-profile'>
                      <StyledAccount>
                        <Avatar src={account?.companyDetail?.url
                          ? `${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${account?.companyDetail?.url}` : account.photoURL} alt="photoURL" />
                        <Box sx={{ ml: 2 }}>
                          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {account.displayName}
                          </Typography>
                        </Box>
                      </StyledAccount>
                    </Link>
                    {/* <div className='toggleMode' style={{ marginTop: '5px' }}>
                <FormControlLabel control={<Switch defaultChecked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} />
              </div> */}
                  </Box>
                </div>
              </div>
            </div>
          </Scrollbar>
        </Drawer>
      )}
    </Box>
  );
}
