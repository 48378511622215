import { Key } from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import { getLocalStoarge } from "../components/common/allFunctions";
let userDetails = window.localStorage.getItem('userDetails');
let updateValue = JSON.parse(userDetails);
let userEmail = window.localStorage.getItem('userEmail');
let userData = '';
let name = '';
let email = '';
let profileImg = '';
let roleId = parseInt(window.localStorage.getItem('roleId'));
let token;
let tokenData;
if (userDetails && updateValue) {
  name = updateValue.firstName + " " + updateValue.lastName;
  profileImg = updateValue.profilePic;
  email = userEmail;
}
else {
  token = localStorage.getItem(roleId == 1 ? "R1" : roleId == 2 ? "R2" : "R3");
  tokenData = JSON.parse(token)?.accessToken;
  if (tokenData) {
    userData = jwt_decode(tokenData);
    name = userData.firstName + " " + userData.lastName;
    email = userData.username;
    profileImg = userData.profilePic;
  }

}

const companyDetail = JSON.parse(localStorage.getItem("c_detail") || "{}")
const account = {
  displayName: userData !== null ? name : '',
  email: userData !== null ? email : '',
  photoURL: (!profileImg) ? '' : process.env.REACT_APP_BACKEND_BASE_URL +
    process.env.REACT_APP_STATIC_PATH +
    profileImg,
  companyDetail
};


export default account;
