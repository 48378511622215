import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    permissionList: [],
};

const subscriptionDetailsSlice = createSlice({
    name: "subscriptionDetails",
    initialState,
    reducers: {
        addSubscriptionDetails: (state, action) => {
            state.subscriptionDetails = action.payload;
        },
        updateSubscriptionDetails: (state, action) => {
            const { id } = action.payload;
            
            state.subscriptionDetails = state.subscriptionDetails?.map((item) =>
                item.id === id
                    ? { ...item, total_added: item.total_added + 1 }
                    : item
            );
        }
    },
});

export const { addSubscriptionDetails, updateSubscriptionDetails } = subscriptionDetailsSlice.actions;

export default subscriptionDetailsSlice.reducer;