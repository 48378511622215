import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/Vendors.css";
// @mui
import {
    Box, TextField,
    Card,
    Container,
    Typography,
    MenuItem,
    IconButton,
    Menu,
    Grid, InputAdornment,
    FormControl,
    Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NewHeader from "../layouts/dashboard/header/NewHeader";

// components
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import Delete from "../assets/delete.svg";
import PostRequest from "../components/apiConnections/postRequest.js";
import ModalDialog from "./ModalDialog.js";
import UpIcon from '../assets/up-icon.svg';
import DownIcon from '../assets/down-arrow.svg';
import SemiChart from "react-apexcharts";
import Trash from '../assets/trahs.svg';
import EyeMenu from '../assets/eye-menu.svg';
import SearchIcon from '../assets/searchico.svg';
import { CustomNoRowsOverlay } from "./Assessment.js"

// Donut chart options and series
const donutoptions = {
    chart: {
        height: 100,
        type: "radialBar",
    },
    plotOptions: {
        radialBar: {
            startAngle: -140,
            endAngle: 140,
            hollow: {
                size: "65%",
            },
            track: {
                background: "#332961", // Track background color
                strokeWidth: "100%", // Track thickness
                margin: 0, // Space between bar and track
            },
            dataLabels: {
                name: {
                    show: true,
                    fontSize: "16px",
                },
                value: {
                    show: true,
                    fontSize: "16px",
                    color: "#fff",
                    offsetY: -10, // Center the value vertically
                    formatter: function (val) {
                        return val + "";
                    },
                },
            },
            rounded: false, // Disable rounded corners
        },
    },
    colors: ["#533460"], // Single color for the segment
    stroke: {
        lineCap: "",
    },
    fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#E6171D"],
            inverseColors: false,
            stops: [0, 100],
        },
    },
    labels: [""],
};

function RowMenu({ handleRemove, handleView }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} className='three-dots-btn'>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className='rowmenu'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleView} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
                <MenuItem onClick={handleRemove}> <img src={Trash} /> Remove</MenuItem>
            </Menu >
        </>
    );
}

export default function VendorEcosystemPageAdmin() {
    const username = window.localStorage.getItem("userEmail")
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [showLoader, setshowLoader] = useState(false);
    const [openAdmin, setOpenAdmin] = useState(false);
    const [adminRemoveId, setAdminRemoveId] = useState(0);
    const roleId = parseInt(window.localStorage.getItem("roleId"));
    const [rows, setRows] = useState([]);

    const [columns, setColumns] = useState([
        { field: "id", headerName: "#", editable: false, maxWidth: 80 },
        {
            field: "name",
            headerName: "Name",
            editable: false,
            minWidth: 180,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: 'score',
            headerName: 'Risk Score',
            flex: 1,
            minWidth: 110,  // Ensure minimum width
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className="relative radialBar-new">
                    {params.value ? <>
                        <SemiChart id="radialBar-new" options={{
                            ...donutoptions,
                            fill: {
                                ...donutoptions.fill,
                                gradient: {
                                    ...donutoptions.fill.gradient,
                                    gradientToColors: [params.value <= 25 ? "#FF0000" : params.value <= 50 ? "#FFA500" : params.value <= 75 ? "#FFD700" : "#008000"]
                                }
                            }
                        }} series={[params.value]} type="radialBar" height={93} />
                        {Number(params.value) !== Number(params.row?.previousScore) ? <span className={`number-up ${Number(params.value) < Number(params.row?.previousScore) ? "number-down" : null}`}>
                            {(Number(params.value) && Number(params.row?.previousScore)) ? <> <img src={
                                Number(params.value) < Number(params.row?.previousScore) ? DownIcon : UpIcon} alt="icon" />{
                                    ((Math.abs(Number(params.value) - Number(params.row?.previousScore)) * 100) / Number(params.row?.previousScore))?.toFixed(2)}%</> : null}
                        </span> : null}
                    </> : "-"}

                </div>
            ),
        },
        {
            field: 'severity', headerName: 'Severity', flex: 1,
            minWidth: 110,
            disableColumnMenu: true,
            renderCell: (params) => {
                let score = params.row.score;
                let cssClass = "";
                let dynamicLable = "-";
                if (score && score <= 25) {
                    cssClass = "criticaldatagrid";
                    dynamicLable = "Critical"
                } else if (score && score <= 50) {
                    cssClass = "highdatagrid";
                    dynamicLable = "High"
                } else if (score && score <= 75) {
                    cssClass = "mediumdatagrid";
                    dynamicLable = "Medium"
                } else if (score && score > 75) {
                    cssClass = "lowdatagrid";
                    dynamicLable = "Low"
                }
                return (
                    dynamicLable ? (
                        <span className={`tagClass ${cssClass}`}>
                            <span className='sqaure-datagrid'>{dynamicLable}</span>
                        </span>
                    ) : (
                        "-"
                    )
                );
            }
        },
        {
            field: "target",
            headerName: "URL",
            editable: false,
            minWidth: 200,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: 'menu1', headerName: '',
            flex: 0.3,
            minWidth: 50,
            align: "right",
            disableColumnMenu: true,
            renderCell: (params) => {
                return <RowMenu
                    data={params.row}
                    handleRemove={() => { handleOpen(params?.row?.target, roleId === 2 ? "admin" : "user") }}
                    handleView={() => { handleRowClick(params) }}
                />
            }
        },
    ]);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);
    const [filterValue, setFilterValue] = useState(null);
    // const [filterSearch, setFilterSearch] = useState(null);

    useEffect(() => {
        setOpenAdmin(adminRemoveId !== 0 ? true : false);
    }, [adminRemoveId]);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (filterValue !== null) getVendorDetails(1, paginationModel.pageSize)
        }, 500)
        return () => clearTimeout(timer)
    }, [filterValue])

    const handleClose = () => {
        setOpenAdmin(false);
        setAdminRemoveId(0);
    };

    const handleOpen = (id, text) => {
        setAdminRemoveId(id);
    };
    const handleRowClick = (params) => {
        navigate(`/${roleId === 2 ? "admin" : "dashboard"}/vendorDetails/${params.row.target}`)
    };

    useEffect(() => {
        getVendorDetails(paginationModel.page + 1, paginationModel.pageSize);
    }, [paginationModel]);

    const getVendorDetails = async (page, limit) => {
        setshowLoader(true);
        setRows([])
        const endpoint = process.env.REACT_APP_VENDORS_LIST;

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?page=${page}&limit=${limit}&search=${filterValue || ""}`,
                    {})
                    .then((resVendors) => {
                        let arrResponse = [];
                        if (Array.isArray(resVendors.data.data.data)) arrResponse = resVendors.data.data.data;
                        setRowCount(resVendors.data.data?.totalVendors || 0);
                        setRows(arrResponse);
                        setRows(
                            arrResponse?.length
                                ? [
                                    ...arrResponse?.map((d, i) => ({
                                        ...d,
                                        id: i + 1,
                                        name:
                                            d.name
                                                ?.substring(0, d.name?.indexOf("."))
                                                .charAt(0)
                                                .toUpperCase() +
                                            d.name
                                                ?.substring(0, d.name?.indexOf("."))
                                                .substr(1)
                                                .toLowerCase(),
                                        risk:
                                            d.score <= 25
                                                ? "Critical"
                                                : d.score <= 50
                                                    ? "High"
                                                    : d.score <= 75
                                                        ? "Medium"
                                                        : "Low",
                                        vendorType: d.vendorType || "Vendor",
                                    })),
                                ]
                                : []
                        );
                        setshowLoader(false);
                    })
                    .catch((errVendor) => {
                        enqueueSnackbar(errVendor.message, { variant: "error" });
                        setshowLoader(false);
                    });
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setshowLoader(false);
        }
    };
    // console.log("rows", rows)
    const removeData = async () => {
        const deleteId = adminRemoveId;
        setshowLoader(true);
        const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            {},
            "refreshToken"
        );
        if (refreshToken) {
            const endpoint = "delete-vendor";
            const payload = {
                vendor: deleteId,
            };
            await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
                payload
            )
                .then((resVendors) => {
                    enqueueSnackbar(resVendors.data.message, { variant: "success" });
                    setshowLoader(false);
                    handleClose();
                    setTimeout(() => {
                        getVendorDetails(paginationModel.page + 1, paginationModel.pageSize);
                    }, 1000);
                })
                .catch((errVendor) => {
                    enqueueSnackbar(errVendor.message, { variant: "error" });
                    setshowLoader(false);
                });
        }
    };

    // const removeLink = async () => {
    //     const deleteId = userRemoveId;
    //     setshowLoader(true);
    //     const refreshToken = await PostRequest(
    //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
    //         {},
    //         {},
    //         "refreshToken"
    //     );
    //     if (refreshToken) {
    //         const endpoint = "unlinked-vendor";
    //         const payload = {
    //             username,
    //             vendor: deleteId,
    //         };
    //         await PostRequest(
    //             `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
    //             payload
    //         )
    //             .then((resVendors) => {
    //                 enqueueSnackbar(resVendors.data.message, { variant: "success" });
    //                 console.log(resVendors.data);
    //                 setshowLoader(false);
    //                 handleClose();
    //                 setTimeout(() => {
    //                     getVendorDetails(paginationModel.page + 1, paginationModel.pageSize);
    //                 }, 1000);
    //             })
    //             .catch((errVendor) => {
    //                 enqueueSnackbar(errVendor.message, { variant: "error" });
    //                 setshowLoader(false);
    //             });
    //     }
    // };

    const ModalContentAdmin = () => {
        return (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <img src={Delete} alt="icon" />
                <h3 className="send-assessments" style={{ whiteSpace: "nowrap" }}>
                    Are you sure you want to remove vendor ?
                </h3>
            </Typography>
        );
    };

    const ModalContentUser = () => {
        return (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <img src={Delete} alt="icon" />
                <h3 className="send-assessments" style={{ whiteSpace: "nowrap" }}>
                    Are you sure you want to remove vendor ?
                </h3>
            </Typography>
        );
    };
    const handlePaginationModelChange = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };

    const handleSearch = (event) => {
        setFilterValue(event.target.value)
    };


    return (
        <>
            {/* <Loader show={showLoader} /> */}
            <Helmet>
                <title> Ecosystem | Genesis Platform</title>
            </Helmet>
            <Container maxWidth={false} className="max-w-left">
                <NewHeader titleName="Ecosystems" />
                <Grid container spacing={3} className="ipad-space">
                    <Grid item xs={12} md={12} lg={12}>

                        <Card className="full-coverdesign margin-questionnaire">
                            <div className="cover-table">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                        top: "4px",
                                        left: "5px",

                                    }}
                                >
                                    <FormControl
                                        className="select-entries"
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Select
                                            value={paginationModel.pageSize}
                                            onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <span className="entries-per">entries per page</span>{" "}
                                </div>
                                <div className="issues-tab-table top-0issues">
                                    <Box
                                        sx={{
                                            width: "100%",
                                            "& .MuiDataGrid-columnHeaders": {
                                                minWidth: "100%",
                                                overflowX: "hidden",
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                minWidth: "100%",
                                                overflowX: "auto",
                                            },
                                        }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search…"
                                            value={filterValue}
                                            onChange={handleSearch}
                                            className='searh-text-field topsearch-top-0'
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={SearchIcon} alt='icon' />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <DataGrid
                                            loading={showLoader}
                                            rows={rows}
                                            columns={columns}
                                            rowHeight={70}
                                            onPaginationModelChange={handlePaginationModelChange}
                                            paginationModel={paginationModel}
                                            rowCount={rowCount}
                                            paginationMode="server"
                                            components={{
                                                NoRowsOverlay: () => (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            textAlign: "center",
                                                            fontSize: "18px",
                                                        }}
                                                    >
                                                        No data
                                                    </Box>
                                                ),
                                            }}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5,
                                                    },
                                                },
                                                filter: {
                                                    filterModel: {
                                                        items: [],
                                                        quickFilterValues: [],
                                                    },
                                                },
                                            }}
                                            autosizeOptions={{
                                                columns: ["id", "firstName", "lastName"],
                                                includeOutliers: true,
                                                includeHeaders: false,
                                            }}
                                            pageSizeOptions={[5, 10, 25, 50]}
                                            slots={{
                                                toolbar: GridToolbar,
                                                noRowsOverlay: CustomNoRowsOverlay,
                                            }}
                                            disableRowSelectionOnClick
                                            disableColumnFilter
                                            disableColumnSelector
                                            disableDensitySelector
                                            disableColumnMenu
                                            disableSelectionOnClick
                                            autoHeight
                                            className="datagrid-table"
                                            sx={{
                                                "& .MuiDataGrid-columnHeaders": {
                                                    minWidth: "100%", // Column headers take up full width
                                                },
                                                "& .MuiDataGrid-virtualScroller": {
                                                    minWidth: "100%", // Rows take up full width
                                                },
                                            }}
                                            onRowClick={handleRowClick}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                <ModalDialog
                    handleClose={handleClose}
                    open={openAdmin}
                    onSubmit={removeData}
                    contentData={ModalContentAdmin}
                    loader={showLoader}
                />
                {/* <ModalDialog
                    handleClose={handleClose}
                    open={openUser}
                    onSubmit={removeLink}
                    contentData={ModalContentUser()}
                /> */}
            </Container >
        </>
    );
}

