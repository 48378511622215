import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Grid,
  Stack,
  TextField,
  Button,
  Chip,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Container,
} from "@mui/material";
import UserProfile from "./../../assets/user-profile.svg";
import Upload from "./../../assets/Vectorplus.svg";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate, useParams } from "react-router-dom";

const CompanyInfo = ({ props, stepper }) => {
  const { id } = useParams();
  const addClientDetails = props.addClientDetails;
  const changeActiveComponent = props.changeActiveComponent;
  const submitData = props.submitData;
  const [formData, setFormData] = useState({
    ...props.client,
    websites: props.client?.websites || [],
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [websites, setWebsites] = useState([]);
  const [websiteInput, setWebsiteInput] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({ ...props.client, websites: props.client?.websites || [] });
    if (props.client?.websites && Array.isArray(props.client?.websites)) setWebsites([...props.client?.websites])
  }, [props.client]);

  useEffect(() => {
    const emailParts = formData.email.split("@");
    if (emailParts.length === 2) {
      setEmailDomain(emailParts[1]);
    } else {
      setEmailDomain("");
    }
  }, [formData.email]);

  useEffect(() => {
    setPhoneNumber(props.client?.mobile || "");
  }, [props.client?.mobile]);

  const handleImageChange = async (e) => {
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/JPEG",
      "image/JPG",
      "image/PNG",
    ];
    const file = e.target.files[0];
    if (!allowedFileTypes.includes(file.type)) {
      enqueueSnackbar("Please upload only supported formats: JPEG, PNG", {
        variant: "error",
      });
      return;
    }

    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        logo: file,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "email") {
      const emailParts = value.split("@");
      if (emailParts.length === 2) {
        setEmailDomain(emailParts[1]);
      } else {
        setEmailDomain("");
      }
    }
  };

  const handleBlur = () => {
    const trimmedInput = websiteInput.trim();
    if (trimmedInput) {
      const websiteArray = trimmedInput
        .split(",")
        .map((website) => website.trim())
        .filter((website) => website !== "");

      for (let item of websiteArray) {
        if (
          !item.match(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/)
        ) {
          enqueueSnackbar("Please enter valid website", {
            variant: "error",
          });
          return;
        }
      }
      const newWebsites = [...formData.websites, ...websiteArray];
      setWebsites(newWebsites);
      setFormData((prevState) => ({
        ...prevState,
        websites: newWebsites,
      }));
      setWebsiteInput("");
    }
  };

  const handleDeleteWebsite = (index) => {
    if (formData.email?.includes(websites[index])) {
      enqueueSnackbar("Please change email domain before removing website", {
        variant: "error",
      });
      return;
    }
    const updatedWebsites = websites.filter((_, i) => i !== index);
    console.log(updatedWebsites, index, props.client?.websites)
    setWebsites(updatedWebsites);
    setFormData((prevState) => ({
      ...prevState,
      websites: updatedWebsites,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleBlur();
    }
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    setFormData((prevState) => ({
      ...prevState,
      mobile: value,
    }));
  };

  const handleChangeTwoFa = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      twoFAMethod: e.target.value,
    }));
  };

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9-.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  const handleEmailDomainChange = (event) => {
    const domain = event.target.value;
    setEmailDomain(domain);
    setFormData((prevState) => ({
      ...prevState,
      email: `${prevState.email.split("@")[0]}@${domain}`,
    }));
  };

  const checkValidation = () => {
    const requiredFields = {
      // logo: "Logo is required",
      company: "Company Name is required",
      firstName: "First name is required",
      lastName: "Last name date is required",
      email: "Email is required",
      mobile: "Mobile is required",
      twoFAMethod: "2FA is required",
    };
    for (const field of Object.keys(requiredFields)) {
      if (!formData[field]) {
        enqueueSnackbar(requiredFields[field], { variant: "warning" });
        return false;
      }
    }
    if(formData?.firstName?.length < 3){
      enqueueSnackbar("First name should contain at least 3 character", { variant: "warning" });
      return false;
    }
    if(formData?.lastName?.length < 3){
      enqueueSnackbar("Last name should contain at least 3 character", { variant: "warning" });
      return false;
    }
    if (formData.websites.length === 0) {
      enqueueSnackbar("Websites are required", { variant: "warning" });
      return false;
    }
    if (formData.websites.length > 0) {
      if (!emailDomain) {
        enqueueSnackbar("Please select email domain", { variant: "warning" });
        return false;
      }
    }
    if (!isValidEmail(formData.email)) {
      enqueueSnackbar("Please enter valid email", { variant: "warning" });
      return false;
    }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      addClientDetails(formData);
      if (formData.clientId) {
        submitData(formData);
      } else {
        changeActiveComponent(2);
      }
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Box>
          <Typography variant="h4">
            <span className="heading-main">
              {formData.clientId ? "Edit Client" : "Add Client"}
            </span>
          </Typography>
        </Box>

      <form className="profile-form" autoComplete="off" onSubmit={onSubmit}>
        <Card sx={{ mt: 1, mb: 5, width: "600px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            {!formData?.clientId && stepper}
          </Stack>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2} className="center-upload">
                <div className="add-client-logo">
                  <Box
                    component="img"
                    alt="Profile Image"
                    src={
                      typeof formData.logo === "string"
                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.logo}`
                        : formData.logo
                          ? URL.createObjectURL(formData.logo)
                          : UserProfile
                    }
                    onError={(e) => e.target.src = UserProfile}
                  />
                </div>
                <Button
                  htmlFor="file-upload"
                  size="small"
                  variant="contained"
                  color="primary"
                  component="label"
                  className="upload-new-plus"
                  title="Select Image"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img src={Upload} alt="icon" />
                    <span>Upload Profile</span>
                  </div>
                </Button>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </Grid>

              <Grid item xs={12} md={10} lg={10}>
                <Stack spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12} pb={1}>
                      <TextField
                        name="company"
                        value={formData.company || ""}
                        label="Company's Legal Name"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} pb={1}>
                      <TextField
                        name="websites"
                        value={websiteInput}
                        label="Company's Websites"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setWebsiteInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        onBlur={handleBlur}
                        placeholder="Enter websites separated by commas"
                      />
                      {formData.websites.length > 0 && (
                        <Box mt={2}>
                          {formData.websites.map((website, index) => (
                            <Chip
                              key={index}
                              label={website}
                              onDelete={() => handleDeleteWebsite(index)}
                              deleteIcon={<CloseIcon />}
                              sx={{ margin: "5px" }}
                            />
                          ))}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="firstName"
                        value={formData.firstName || ""}
                        label="First Name"
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="lastName"
                        label="Last Name"
                        value={formData.lastName || ""}
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <FormControl fullWidth>
                        <TextField
                          disabled={id ? true : false}
                          name="email"
                          value={formData.email}
                          label="Email"
                          onChange={handleInputChange}
                          InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {formData.websites.length > 0 && (
                                <Select
                                  value={emailDomain}
                                  onChange={handleEmailDomainChange}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Email Domain",
                                  }}
                                  className="companyInfo-input-adornment"
                                >
                                  <MenuItem value="">Select Domain</MenuItem>
                                  {formData.websites.map((website, index) => (
                                    <MenuItem key={index} value={website}>
                                      {`@${website}`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <MuiPhoneNumber
                        InputComponent={(params) => <TextField {...params} />}
                        variant="outlined"
                        label="Phone Number"
                        defaultCountry={"us"}
                        value={phoneNumber}
                        onChange={(value) => {
                          handlePhoneChange(value);
                        }}
                        disableAreaCodes={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          className="gender"
                          sx={{
                            // color: "#242526",
                            fontFamily: "Poppins",
                            width: "250px",
                          }}
                        >
                          2FA Method :{" "}
                        </Box>
                        <FormControl>
                          <RadioGroup
                            row
                            name="2fa-method"
                            value={formData.twoFAMethod}
                            onChange={handleChangeTwoFa}
                          >
                            <FormControlLabel
                              value="email"
                              control={<Radio />}
                              label="Email"
                            />
                            <FormControlLabel
                              value="sms"
                              control={<Radio />}
                              label="SMS"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
                <br />
                <div style={{ display: "flex", justifyContent: "end", gap: "2%" }}>
                  <Button
                    variant="contained"
                    className="add-another-button"
                    onClick={() => navigate("../clients-management")}
                  >
                    Cancel
                  </Button>
                  {formData.clientId ? (
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      color="success"
                      className="add-another-button"
                    >
                      Submit
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="success"
                     className="add-another-button"
                    >
                      Next
                    </LoadingButton>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Card>
        </form>
        </Container>
    </>
  );
};

export default CompanyInfo;
