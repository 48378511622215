import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  Container,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Chip,
  Box,
  CircularProgress,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from 'react-router-dom';
import BackArrow from '../assets/back-arrow.svg';
import ProfileIcon from '../assets/profile-icon.svg';
import EditIcon from '../assets/edit-icon.svg';
import OtherInformation from '../assets/other-information.svg';
import ProfileImage from '../assets/profile-information-image.png';
import RelationshipImg from '../assets/relationship-info.png';
import DocumentIcon from '../assets/document-icon.png';
import DeleteBin from "../assets/delete-bin.svg";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import PostRequest from '../components/apiConnections/postRequest';
import GetRequest from '../components/apiConnections/getRequest';
import MuiPhoneNumber from 'material-ui-phone-number';
import CalendarTodayIcon from '../assets/calender-icon-select.svg'
import { industryArray } from '../components/constant/industryConstant';

function AddNewVendorByClient(props) {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const roleId = localStorage.getItem("roleId");
  const { domain: domain } = location?.state || props;
  const { vendorStatus: status } = location?.state || props;
  const { jsonAvailableData: jsonAvailable } = location?.state || props;
  const handleClose = props.handleClose  || null ; 
  const [errors, setErrors] = useState({});
  const [docErrors, setDocErrors] = useState({});
  const [availableDocument, setAvailableDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const [getDataSensitivityRisk, setGetDataSensitivityRisk] = useState(0);
  const [getDependencyLevelRisk, setGetDependencyLevelRisk] = useState(0);
  const [getAccessLevelRisk, setGetAccessLevelRisk] = useState(0);
  const [getComplianceLevelRisk, setGetComplianceLevelRisk] = useState(0);

  const [finalRelationshipRisk, setFinalRelationshipRisk] = useState();
  const [showRiskTag, setShowRiskTag] = useState(false);
  const allTabsInformationRef = useRef(null); 
  const [formData, setFormData] = useState({
    domain: domain,
    logo: '',
    name: '',
    industry: '',
    Subindustry: '',
    numberOfEmployees: '',
    linkedin: '',
    country: '',
    address: '',
    description: '',
    contactInformation: [{ firstName: '', lastName: '', email: '', mobile: '', isPrimary: true }],
    acceptableRisk: '',
    customTags: [],
    contractDeadline: '',
    documents: [{ documentType: '', isUpload: true, documentFile: null, type: '' }],
    dataSensitivity: [],
    accessLevel: [],
    dependencyLevel: [],
    complianceLevel: [],
    vendorStatus: status,
    revenue: ''
  });
  const dependencyLevelArr = [
    "Low (Easily Replaceable)",
    "Moderate",
    "High (Significant Impact if Replaced)",
    "Critical",
  ]
  const dataSensitivityOptions = [
    "Non-sensitive",
    "Internal business data",
    "Personal data (PII)",
    "Customer data",
    "Financial data",
    "Intellectual property",
    "Health data",
    "Legal data",
    "Confidential business plans"
  ];
  const accessLevelOptions = [
    "No access",
    "Limited access",
    "Read-Write access",
    "Operational access",
    "Full access"
  ];
  const complianceOptions = [
    "ISO",
    "GDPR",
    "NIST"
  ]
  const acceptableRiskArr = Array.from({ length: 100 }, (_, i) => i + 1);

  const subIndustries = industryArray.find((industry) => industry.industryType === formData.industry)
    ?.subIndustries || [];
  useEffect(() => {
    getCompaniesDetails();
    getDocuments();
  }, [])

  useEffect(() => {
    setFinalRelationshipRisk((getDataSensitivityRisk +
      getAccessLevelRisk +
      getDependencyLevelRisk +
      getComplianceLevelRisk))?.toFixed(2);
  }, [getDataSensitivityRisk, getAccessLevelRisk, getDependencyLevelRisk, getComplianceLevelRisk]);



  const getCompaniesDetails = async () => {
    try {
      let logo = null;
      axios.get(`${process.env.REACT_APP_THIRD_PARTY_API_TO_FEATCH_VENDOR_DETAILS}/${domain}?token=${process.env.REACT_APP_THIRD_PARTY_API_TO_TOKEN}`).then(res => {
        const data = res?.data;
        logo = `${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${domain}`;
        if (data?.domainName) {
          setFormData((prev) => ({
            ...prev,
            logo: logo || '',
            name: data.name || '',
            // industries: data.industryMain || '',
            numberOfEmployees: data.totalEmployees || '',
            linkedin: data.socialNetworks?.linkedin || '',
            country: data.country?.name || '',
            description: data.description || '',
            address: data.city?.name || '',
            revenue: data?.revenue || ''
          }));
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (error) {
      console.error("Error fetching vendor data:", error);
    }
  }
  const getDocuments = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOCUMENT_TYPE}`,
          {}
        )
          .then((response) => {
            setAvailableDocuments(response.data.data);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });

          })
      } else {
        console.log("Refresh token is expaired!");
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value, type, checked } = e.target;
    if(name==="industry"){
      formData.Subindustry=""; 
    }
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: ''
    }));

  };
  const handleContactChange = (index, e) => {
    const { name, value } = e.target;
    const contacts = [...formData.contactInformation];
    contacts[index][name] = value;
    contacts.forEach((contact, i) => {
      contact.isPrimary = i === 0;
    });
    setFormData((prev) => ({
      ...prev,
      contactInformation: contacts
    }));
    setErrors((prev) => ({
      ...prev,
      [`contact-${index}-${name}`]: ''
    }));
  };
  const validateForDocument = () => {
    const docErrors = {};
    // Validate documents
    formData.documents.forEach((document, index) => {
      if (!document.documentType) {
        docErrors[`document-${index}-type`] = '*Please select a document type';
      }
      if (!document.documentFile && document.isUpload!==false) {
        docErrors[`document-${index}-file`] = '*Please upload a document file';
      }
    });
    setDocErrors(docErrors);
    return Object.keys(docErrors).length === 0;
  };

  const handleAddContact = () => {
    setFormData((prev) => ({
      ...prev,
      contactInformation: [...prev.contactInformation, { firstName: '', lastName: '', email: '', mobile: '', isPrimary: false }]
    }));
  };
  const handleDeleteContact = (index) => {
    const updatedContacts = formData.contactInformation.filter((_, i) => i !== index);
    setFormData({ ...formData, contactInformation: updatedContacts });
  };
  const handleDeletedOC = (index) => {
    const updatedDOC = formData.documents.filter((_, i) => i !== index);
    setFormData({ ...formData, documents: updatedDOC });
    setDocErrors({})
  };
  const theme = localStorage.getItem("theme");

  const formSubmit = async (e) => {
    e.preventDefault();
  }

  const handleSave = async (e) => {
    setLoading(true);
    if (validateFields() && validateForDocument()) {
      let payload = new FormData();
      const documents = [];
      for (let item of formData.documents) {
        if (item.documentFile && item.isUpload) payload.append("documentFiles", item.documentFile)
        documents.push({
          type: item.type,
          documentType: item.documentType,
          isRequest: item.isRequest,
          isUpload: item.isUpload,
          documentName: item.documentFile ? item.documentFile?.name : null
        })
      }
      // let getIndustries = formData.industry ? formData.industry?.split(",") : [];
      // let getSubIndustries = formData.Subindustry ? formData.Subindustry?.split(",") : [];
      const industries= [formData.industry]; 
      const subIndustries = [formData.Subindustry]; 
      payload.append("domain", domain)
      if (!props.domain) {
        if (roleId == 2) payload.append("clientEmail", location.state?.clientEmail)
        else payload.append("clientEmail", localStorage.getItem("userEmail"))

        payload.append("vendorStatus", status)
      }
      payload.append("name", formData.name)
      payload.append("logo", formData.logo || null)
      payload.append("industries", JSON.stringify(industries))
      payload.append("Subindustry", JSON.stringify(subIndustries))
      payload.append("numberOfEmployees", formData.numberOfEmployees)
      payload.append("linkedIn", formData.linkedin)
      payload.append("country", formData.country)
      payload.append("address", formData.address)
      payload.append("description", formData.description)
      payload.append("revenue", formData.revenue)
      payload.append("contactInformation", JSON.stringify(formData.contactInformation))
      payload.append("relationships", JSON.stringify({
        dataSensitivity: formData.dataSensitivity,
        accessLevel: formData.accessLevel,
        dependencyLevel: formData.dependencyLevel,
        complianceLevel: formData.complianceLevel
      }))
      payload.append("acceptableRisk", formData.acceptableRisk)
      payload.append("customTags", JSON.stringify(formData.customTags))
      payload.append("contractDeadline", formData.contractDeadline)
      payload.append("documents", JSON.stringify(documents))
      console.log("payload" , payload); 
      if (props.domain) {
        props.handleSuccess(payload)
        return;
      }
      try {
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          {},
          "refreshToken"
        );
        let endPoint = roleId == 2 ? process.env.REACT_APP_ADD_CLIENT_VENDOR_BY_ADMIN :
          process.env.REACT_APP_ADD_VENDOR_DETAILS
        if (refreshToken) {
          await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
            payload
          )
            .then((resChart) => {
              enqueueSnackbar('Vendor added successfully', { variant: 'success' });
              setTimeout(() => {
                if (roleId == 2) {
                  navigate(`/admin/client-details/${location.state?.clientId}`, { state: { tab: 1 } })
                } else {
                  if (jsonAvailable) {
                    navigate(`/dashboard/vendorDetails/${domain}`);
                  } else navigate(`/dashboard/vendors`);
                }
              }, 2000)
              setLoading(false);
            })
            .catch((errChart) => {
              enqueueSnackbar(errChart.message, { variant: 'error' });
              setLoading(false)
            })
        } else {
          setLoading(false);
        }
      }
      catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        setLoading(false);
      }
    } else {
      enqueueSnackbar("Please provide valid details", { variant: 'error' });
      setLoading(false);
    }
  };

  const getCurrentDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const calculateRelationShipRisk = (value, key) => {
    if (value && key) {
      if (key == "dataSensitivity") {
        switch (value) {
          case "Non-sensitive":
            setGetDataSensitivityRisk(100 * (30 / 100));
            break;
          case "Internal business data":
            setGetDataSensitivityRisk(90 * (30 / 100));
            break;
          case "Personal data (PII)":
            setGetDataSensitivityRisk(70 * (30 / 100));
            break;
          case "Customer data":
            setGetDataSensitivityRisk(60 * (30 / 100));
            break;
          case "Financial data":
            setGetDataSensitivityRisk(40 * (30 / 100));
            break;
          case "Intellectual property":
            setGetDataSensitivityRisk(40 * (30 / 100));
            break;
          case "Health data":
            setGetDataSensitivityRisk(0 * (30 / 100));
            break;
          case "Legal data":
            setGetDataSensitivityRisk(40 * (30 / 100));
            break;
          case "Confidential business plans":
            setGetDataSensitivityRisk(40 * (30 / 100));
            break;
          default:
            setGetDataSensitivityRisk(0);
        }
      }

      if (key == "accessLevel") {
        switch (value) {
          case "No access":
            setGetAccessLevelRisk(100 * (25 / 100));
            break;
          case "Limited access":
            setGetDataSensitivityRisk(90 * (25 / 100));
            break;
          case "Read-Write access":
            setGetDataSensitivityRisk(70 * (25 / 100));
            break;
          case "Operational access":
            setGetDataSensitivityRisk(40 * (25 / 100));
            break;
          case "Full access":
            setGetDataSensitivityRisk(0 * (25 / 100));
            break;
          default:
            setGetDataSensitivityRisk(0);
        }
      }

      if (key == "dependencyLevel") {
        switch (value) {
          case "Low (Easily Replaceable)":
            setGetDependencyLevelRisk(100 * (20 / 100));
            break;
          case "Moderate (Some Impact if Replaced)":
            setGetDependencyLevelRisk(70 * (20 / 100));
            break;
          case "High (Significant Impact if Replaced)":
            setGetDependencyLevelRisk(40 * (20 / 100));
            break;
          case "Critical (Not Easily Replaceable)":
            setGetDependencyLevelRisk(0);
            break;
        }
      }

      if (key == "complianceLevel") {
        switch (value) {
          case "ISO":
            setGetComplianceLevelRisk(70 * (25 / 100));
            break;
          case "NIST":
            setGetComplianceLevelRisk(40 * (25 / 100));
            break;
          case "GDPR":
            setGetComplianceLevelRisk(0 * (25 / 100));
            break;
          default:
            setGetComplianceLevelRisk(100);
        }
      }
    }
  }
  const handleCheckboxChange = (event, key) => {
    setShowRiskTag(true);
    const { value } = event.target;
    calculateRelationShipRisk(value, key);
    setFormData((prevData) => ({
      ...prevData,
      [key]: [value],
    }));
  };

  const validateFields = () => {
    const newErrors = {};
 
    if (!formData.name) newErrors.name = '*Please enter Business Name';
    if (!formData.country) newErrors.country = '*Please enter Country.';
    if (!formData.industry) newErrors.industry = '*Please select Industry.';
    if (!formData.Subindustry) newErrors.Subindustry = '*Please select Subindustry.';
    if (!formData.numberOfEmployees) newErrors.numberOfEmployees = '*Please enter Number Of Employees.';
    if (!formData.country) newErrors.country = '*Please enter Country.';

    if (!formData.acceptableRisk) newErrors.acceptableRisk = '*please select Acceptable Risk';
    if (!formData.contractDeadline) newErrors.contractDeadline = '*please select Contract Deadline';

    // Validate contacts
    formData.contactInformation.reduce((acc, contact, index) => {
      if (!contact.firstName) {
        acc[`contact-${index}-firstName`] = '*Please enter Contact First Name.';
      }
      if (!contact.lastName) {
        acc[`contact-${index}-lastName`] = '*Please enter Contact Last Name.';
      }
      if (!contact.email) {
        acc[`contact-${index}-email`] = '*Please enter Contact Email.';
      }
      return acc;
    }, newErrors);
    // if(finalRelationshipRisk<=0) newErrors.relationshipError = '*please select risk check boxes'
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const validateFileType = (file) => {
    const validFileTypes = [
      'application/pdf',
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      // 'application/vnd.ms-excel' // .xls
    ];

    return file ? validFileTypes.includes(file.type) : false;
  };


  const handleFileChange = (e, index) => {
    const file = e.target.files[0];

    if (validateFileType(file)) {
      const updatedDocuments = [...formData.documents];
      updatedDocuments[index].documentFile = file;
      setFormData((prev) => ({ ...prev, documents: updatedDocuments }));

      setDocErrors((prevErrors) => ({
        ...prevErrors,
        [`document-${index}-file`]: ''
      }));
    } else {
      // Reset the file input
      e.target.value = '';

      // Set error message for invalid file type
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`document-${index}-file`]: 'Only PDF files are allowed'
      }));
    }
  };
  const handleNext = () => {
    if (validateFields()) {
      if (currentStep < 2) {
        setCurrentStep(currentStep + 1);
          // Scroll to the div with class 'all-tabsinformation'
          if (allTabsInformationRef.current) {
            allTabsInformationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
              // Scroll to the div with class 'all-tabsinformation'
              if (allTabsInformationRef.current) {
                allTabsInformationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      const value = inputValue.trim();
      if (value && !formData.customTags.includes(value)) {
        setFormData(prev => ({
          ...prev,
          customTags: [...prev.customTags, value]
        }));
      }
      setInputValue('');
    }
  };

  const handleDelete = (tagToDelete) => {
    setFormData(prev => ({
      ...prev,
      customTags: prev.customTags.filter((tag) => tag !== tagToDelete)
    }));
  };

  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <form onSubmit={(e) => formSubmit(e)}>
        <Container maxWidth={false} className="max-w-left">
          <Grid container spacing={3} className="ipad-space">
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                <span className='backlink'>
                  <img src={BackArrow} alt='icon' onClick={handleClose ?  handleClose : () =>  navigate(-1)} /> Add New Vendor
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <div>
                <div className='heading-add-vendor'>
                  <p>New Registration ( Complete this basic information)</p>
                </div>
                <div className='all-tabsinformation' ref={allTabsInformationRef}>
                  <div className='three-steps-tabs add-newvendor-steps'>
                    <div className={`select-steps ${currentStep >= 0 ? 'behind-line activeprofile' : ''}`}>
                      <div className='steps-image'>
                        <img src={ProfileImage} alt='icon' />
                      </div>
                      <p>Profile Information </p>
                    </div>
                    <div className={`select-steps behind-line ${currentStep >= 1 ? 'behind-line' : ''} ${currentStep >= 1 ? 'activeprofile' : ''}`}>
                      <div className='steps-image '>
                        <img src={RelationshipImg} alt='icon' />
                      </div>
                      <p>Relationship Information</p>
                    </div>
                    <div className={`select-steps ${currentStep >= 2 ? 'activeprofile' : ''}`}>
                      <div className='steps-image'>
                        <img src={DocumentIcon} alt='icon' />
                      </div>
                      <p>Document Information</p>
                    </div>
                  </div>

                  {/*First Tab Start*/}
                  {currentStep === 0 && (
                    <div className='information-tab-heading'>
                      <p><img src={ProfileIcon} width={45} alt='profile icon' /> Profile Information </p>
                      <img src={EditIcon} alt="icon" />
                    </div>
                  )}
                  <div className='input-information'>
                    {currentStep === 0 && (
                      <>
                        <div className='vendor-logoinformation'>
                          <div className='logo-vender-new'>
                            <div className='add-newvendor-logo-here'> <img
                              src={formData.logo}
                              alt="Company Logo"
                            /></div>
                          </div>
                          <div className='right-input-fields'>
                            <div className='flex-inputcontrol-full'>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  name="name"
                                  label="Business Name"
                                  className='add-vender-input-design'
                                  value={formData.name}
                                  onChange={(e) => handleChange(e)}
                                  onBlur={(e) => handleChange(e)}
                                  required
                                  maxLength={80}
                                  error={!!errors[`name`]}
                                  helperText={errors[`name`]}
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel id="industry-label" shrink={formData.industry !== ""}
                                  sx={{
                                    '&.Mui-focused': {
                                      color: formData.industry ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                    },
                                  }}>Select Industry</InputLabel>
                                <Select
                                  labelId="industry-label"
                                  id="industry"
                                  name="industry"
                                  value={formData.industry}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  label="industry-label"
                                  error={!!errors.industry}
                                  helperText={errors.industry}
                                  className='add-vender-select-value'>
                                  <MenuItem value="" disabled>Industry type  </MenuItem>
                                  {industryArray.map((industry) => (
                                    <MenuItem key={industry.industryType} value={industry.industryType}>{industry.industryType}</MenuItem>
                                  ))}
                                </Select>
                                {!!errors.industry && (
                                  <FormHelperText sx={{ color: 'red!important' }}>{errors.industry}</FormHelperText>
                                )}
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel id="subIndustry-label" shrink={formData.Subindustry !== ""}
                                  sx={{
                                    '&.Mui-focused': {
                                      color: formData.Subindustry ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                    },
                                  }}>Select Subindustry</InputLabel>
                                <Select
                                  labelId="Subindustry-label"
                                  id="Subindustry"
                                  name="Subindustry"
                                  value={formData.Subindustry}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  label="Subindustry-label"
                                  error={!!errors.Subindustry}
                                  helperText={errors.Subindustry}
                                  className='add-vender-select-value'>
                                  <MenuItem value="" disabled> Subindustry type </MenuItem>
                                  {subIndustries.map((industry) => (
                                    <MenuItem key={industry} value={industry}>{industry}</MenuItem>
                                  ))}
                                </Select>
                                {!!errors.Subindustry && (
                                  <FormHelperText sx={{ color: 'red!important' }}>{errors.Subindustry}</FormHelperText>
                                )}
                              </FormControl>
                            </div>
                            <div className='flex-inputcontrol-full'>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  name="numberOfEmployees"
                                  label="Number of Employess"
                                  value={formData.numberOfEmployees}
                                  onChange={(e) => handleChange(e)}
                                  type="string"
                                  className='add-vender-input-design'
                                  error={!!errors[`numberOfEmployees`]}
                                  helperText={errors[`numberOfEmployees`]}
                                  required
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  label="LinkedIn Profile"
                                  name="linkedin"
                                  value={formData.linkedin}
                                  onChange={(e) => handleChange(e)}
                                  className='add-vender-input-design'
                                />
                              </FormControl>
                            </div>
                            <div className='flex-inputcontrol-full'>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  label="Country"
                                  name="country"
                                  value={formData.country}
                                  onChange={(e) => handleChange(e)}
                                  onBlur={(e) => handleChange(e)}
                                  className='add-vender-input-design'
                                  error={!!errors[`country`]}
                                  helperText={errors[`country`]}
                                  required
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  label="Address"
                                  name="address"
                                  value={formData.address}
                                  onChange={(e) => handleChange(e)}
                                  className='add-vender-input-design'
                                  required
                                />
                              </FormControl>
                            </div>
                            <FormControl fullWidth>
                              <TextField
                                name="description"
                                label="Description"
                                value={formData.description}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                className=''
                                required
                              /></FormControl>
                          </div>
                        </div>
                        <div className='information-tab-heading'>
                          <p><img src={ProfileIcon} width={45} alt='profile icon' /> Contact Information</p>
                        </div>
                        {formData.contactInformation.map((contact, index) => (
                          <>
                            <div className='contact-information-vender-new'>
                              {index == 0 ? (<p className='primary-text-contact'>Primary Contact</p>) :
                                (<p className='primary-text-contact'>{index} Secondary Contacts</p>)
                              }
                              {index > 0 && (
                                <img className='delete-bin-icon' onClick={() => handleDeleteContact(index)} src={DeleteBin} alt='icon' style={{ cursor: 'pointer' }} />
                              )}
                            </div>

                            <div className='vendor-logoinformation'>
                              <div className='logo-vender-new'>
                              </div>
                              <div className='right-input-fields'>
                                <div className='flex-inputcontrol-full'>
                                  <FormControl fullWidth>
                                    <TextField
                                      label="First Name"
                                      name="firstName"
                                      className='add-vender-input-design'
                                      value={contact.firstName}
                                      onChange={(e) => handleContactChange(index, e)}
                                      required
                                      maxLength={30}
                                      error={!!errors[`contact-${index}-firstName`]}
                                      helperText={errors[`contact-${index}-firstName`]}
                                    />
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <TextField
                                      name="lastName"
                                      variant="outlined"
                                      label="Last Name"
                                      value={contact.lastName}
                                      onChange={(e) => handleContactChange(index, e)}
                                      required
                                      maxLength={30}
                                      className='add-vender-input-design'
                                      error={!!errors[`contact-${index}-lastName`]}  // dynamic error key
                                      helperText={errors[`contact-${index}-lastName`]}
                                    />
                                  </FormControl>
                                </div>
                                <div className='flex-inputcontrol-full'>
                                  <FormControl fullWidth>
                                    <TextField
                                      name="email"
                                      variant="outlined"
                                      label="Email"
                                      value={contact.email}
                                      onChange={(e) => handleContactChange(index, e)}
                                      maxLength={40}
                                      required
                                      className='add-vender-input-design'
                                      error={!!errors[`contact-${index}-email`]}  // dynamic error key
                                      helperText={errors[`contact-${index}-lastName`]}
                                    />
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <MuiPhoneNumber
                                      variant="outlined"
                                      className='add-vender-input-design'
                                      label="Phone Number"
                                      name="mobile"
                                      defaultCountry={"us"}
                                      value={contact.mobile}
                                      onChange={(value) => handleContactChange(index, { target: { name: "mobile", value } })}
                                      disableAreaCodes={true}
                                      fullWidth
                                      sx={{ mb: 2 }}
                                    />
                                  </FormControl>
                                </div>
                                {index === formData.contactInformation.length - 1 && (
                                  <Button variant="contained" className='addsection add-contact-button' onClick={() => handleAddContact()}>
                                    Add Another Contact
                                  </Button>
                                )}

                              </div>
                            </div>
                          </>
                        ))}

                        <div className='information-tab-heading'>
                          <p><img src={OtherInformation} width={45} alt='profile icon' /> Other Information </p>
                        </div>
                        <div className='vendor-logoinformation'>
                          <div className='logo-vender-new'>
                          </div>
                          <div className='right-input-fields'>
                            <div className='flex-inputcontrol-full'>
                              <FormControl fullWidth>
                                <InputLabel id="acceptable-risk-label" shrink={formData.acceptableRisk !== ""}
                                  sx={{
                                    '&.Mui-focused': {
                                      color: document.documentType ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                    },
                                  }}>Select Acceptable Risk</InputLabel>
                                <Select
                                  labelId="acceptable-risk-label"
                                  id="acceptable-risk"
                                  name="acceptableRisk"
                                  value={formData.acceptableRisk}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  label="Acceptable Risk"
                                  error={!!errors.acceptableRisk}
                                  helperText={errors.acceptableRisk}
                                  className='add-vender-select-value'>
                                  <MenuItem value="" disabled>Acceptable Risk</MenuItem>
                                  {acceptableRiskArr.map((num) => (
                                    <MenuItem key={num} value={num}>{num}</MenuItem>
                                  ))}
                                </Select>
                                {!!errors.acceptableRisk && (
                                  <FormHelperText sx={{ color: 'red!important' }}>{errors.acceptableRisk}</FormHelperText>
                                )}
                              </FormControl>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  className='add-vender-input-design'
                                  type="date"
                                  name="contractDeadLine"
                                  InputLabelProps={{ shrink: true, required: true }}
                                  value={formData.contractDeadline || ""}
                                  title="Select Date"
                                  label="Contract Deadline"
                                  error={!!errors.contractDeadline}
                                  helperText={errors.contractDeadline}
                                  // onChange={(e) => setFormData({ ...formData, contractDeadline: e.target.value })}
                                  onChange={(e) => {
                                    const selectedDate = e.target.value;
                                    setFormData({ ...formData, contractDeadline: selectedDate });

                                    // Clear error if a date is selected
                                    if (selectedDate) {
                                      setErrors({ ...errors, contractDeadline: "" });
                                    }
                                  }}
                                  inputProps={{
                                    min: getCurrentDate(new Date()),
                                    onKeyDown: (e) => e.preventDefault(),
                                    style: {
                                      WebkitAppearance: 'none',
                                      MozAppearance: 'textfield',
                                      appearance: 'none',
                                      position: 'relative',
                                    },
                                  }}
                                  sx={{ color: "white" }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <img
                                          src={CalendarTodayIcon}
                                          alt="calendar icon"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            document.getElementsByName('contractDeadLine')[0].showPicker();
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  name="customTags"
                                  label="Custom Tags"
                                  className='add-vender-input-design'
                                  value={inputValue}
                                  onChange={(e) => setInputValue(e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e)}
                                />
                                <Box className='tags-container'>
                                  {formData.customTags.map((tag, index) => (
                                    <Chip
                                      key={index}
                                      label={tag}
                                      onDelete={() => handleDelete(tag)}
                                      style={{ margin: '4px' }}
                                    />
                                  ))}
                                </Box>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <Button variant="contained" color="primary" className="add-another-btn-doc next-will-space" onClick={handleNext}>Next</Button>
                      </>
                    )}
                    {/*Second Tab Start*/}
                    {currentStep === 1 && (
                      <>
                        <div className='information-tab-heading'>
                          <p><img src={OtherInformation} width={45} alt='profile icon' /> Relationship  Information
                          </p>
                        </div>

                        <div className='vendor-logoinformation'>
                          <div className='logo-vender-new'>
                          </div>
                          <div className='right-input-fields'>
                            <div className='realtionship-info-tab tab-flex-realtionship'>
                              <p className='relationship-text-tag' > Relationship Risk:<span></span></p>
                              { showRiskTag && (
                                finalRelationshipRisk >= 0 && finalRelationshipRisk <= 25 ? (
                                  <span className={`tagClass Critical`}>
                                    Critical
                                  </span>
                                ) : finalRelationshipRisk >25 && finalRelationshipRisk <= 50 ? (
                                  <span className={`tagClass High`}>
                                    High
                                  </span>
                                ) : finalRelationshipRisk >50 && finalRelationshipRisk <= 75 ? (
                                  <span className={`tagClass Medium`}>
                                    Medium
                                  </span>
                                ) : finalRelationshipRisk >75 && finalRelationshipRisk <= 100 ? (
                                  <span className={`tagClass Low`}>
                                    Low
                                  </span>
                                ) : (
                                  "-"
                                ))
                              }
                            </div>
                            <div className='data-sensitivity'>
                              <h3>Data Sensitivity</h3>
                              <p>What kind of data does the vendor hold?</p>
                              <div className='check-boxes-multiples'>
                                <Grid container spacing={3}>
                                  {dataSensitivityOptions.map((option) => (
                                    <Grid item xs={12} md={3} key={option}>
                                      <div className="d-flex-checks">
                                        <div className="inline-form-check checkbox-outline">
                                          <label className="custom-checkbox">
                                            <input
                                              type="checkbox"
                                              id="flexCheckDefault1"
                                              name="dataSensitivity"
                                              value={option}
                                              checked={formData.dataSensitivity.includes(option)}
                                              onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="assessment-datacheck">{option}</span>
                                          </label>
                                        </div>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            </div>
                            {/*Second copy*/}
                            <div className='data-sensitivity'>
                              <h3>Access level</h3>
                              <p>What kind of access does the vendor hold?</p>
                              <div className='check-boxes-multiples'>
                                <Grid container spacing={3} >
                                  {accessLevelOptions.map((option) => (
                                    <Grid item xs={12} md={3} key={option}>
                                      <div className="d-flex-checks">
                                        <div className="inline-form-check checkbox-outline">
                                          <label className="custom-checkbox">
                                            <input
                                              type="checkbox"
                                              id="flexCheckDefault1"
                                              name="accessLevel"
                                              value={option}
                                              checked={formData.accessLevel.includes(option)}
                                              onChange={(e) => handleCheckboxChange(e, 'accessLevel')}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="assessment-datacheck">{option}</span>
                                          </label>
                                        </div>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            </div>
                            {/*Dependency Level*/}
                            <div className='data-sensitivity' >
                              <h3>Dependency level</h3>
                              <p>Is the vendor compliant with compliance?</p>
                              <div className='check-boxes-multiples'>
                                <Grid container spacing={3}>
                                  {dependencyLevelArr.map((option) => (
                                    <Grid item xs={12} md={3} key={option}>
                                      <div className="d-flex-checks">
                                        <div className="inline-form-check checkbox-outline">
                                          <label className="custom-checkbox">
                                            <input
                                              type="checkbox"
                                              id="flexCheckDefault1"
                                              name="dependencyLevel"
                                              value={option}
                                              checked={formData.dependencyLevel.includes(option)}
                                              onChange={(e) => handleCheckboxChange(e, 'dependencyLevel')}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="assessment-datacheck">{option}</span>
                                          </label>
                                        </div>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            </div>
                            {/*Compliance*/}
                            <div className='data-sensitivity'>
                              <h3>Compliance</h3>
                              <p>which complaince vendor does hold?</p>
                              <div className='check-boxes-multiples'>
                                <Grid container spacing={3}>
                                  {complianceOptions.map((option) => (
                                    <Grid item xs={12} md={3} key={option}>
                                      <div className="d-flex-checks">
                                        <div className="inline-form-check checkbox-outline">
                                          <label className="custom-checkbox">
                                            <input
                                              type="checkbox"
                                              id="flexCheckDefault1"
                                              name="complianceLevel"
                                              value={option}
                                              checked={formData.complianceLevel.includes(option)}
                                              onChange={(e) => handleCheckboxChange(e, 'complianceLevel')}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="assessment-datacheck">{option}</span>
                                          </label>
                                        </div>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            </div>
                          </div>
       
                        </div>
                        {/* {!!errors[`relationshipError`] && (
                          <span style={{ color: "red", fontSize: "12px"}}>
                            {errors[`relationshipError`]}
                          </span>
                        )} */}
                        <div style={{ margin: "0px 25px 0px auto", display: "flex", width: "fit-content" }}>
                        
                          <Button variant="contained" color="primary" className="add-another-btn-doc marin-right-another-button w120-fix" onClick={() => handleBack()}>Back</Button>
                          <Button variant="contained" color="primary" className="add-another-btn-doc w120-fix" onClick={() => handleNext()}>Next</Button>
                        </div>
                      </>
                    )}

                    {/*Third Tab Start*/}
                    {currentStep === 2 && (
                      <>
                        <div className='information-tab-heading'>
                          <p><img src={OtherInformation} width={45} alt='profile icon' /> Document Information
                          </p>
                        </div>
                        {formData.documents.map((document, index) => {
                          // Get available document types by filtering out the selected ones
                          const availableDocumentTypes = availableDocument?.filter((docType) =>
                            !formData.documents.some((doc, i) => doc.documentType === docType.name && i !== index)
                          );

                          return (
                            <>
                              <div className='vendor-logoinformation'>
                                <div className='logo-vender-new'></div>
                                <div className='upload-document-button'>
                                  <div className='flex-uploadbtn-flex'>
                                    <div className='radio-type-design'>
                                      <input
                                        type="radio"
                                        id={`upload-${index}`}
                                        name={`documentUploadRequest-${index}`}
                                        checked={document.isUpload}
                                        onChange={(e) => {
                                          const updatedDocuments = [...formData.documents];
                                          updatedDocuments[index].isUpload = e.target.checked;
                                          setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                        }}
                                      />
                                      <label htmlFor={`upload-${index}`}>Upload Document</label>
                                    </div>

                                    <div className='radio-type-design'>
                                      <input
                                        type="radio"
                                        id={`request-${index}`}
                                        name={`documentUploadRequest-${index}`}
                                        checked={!document.isUpload}
                                        onChange={(e) => {
                                          const updatedDocuments = [...formData.documents];
                                          updatedDocuments[index].isUpload = !e.target.checked;
                                          setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                        }}
                                      />
                                      <label htmlFor={`request-${index}`}>Request Document</label>
                                    </div>
                                  </div>

                                  <div className='select-document-box-type'>
                                    <img src={DeleteBin} onClick={() => handleDeletedOC(index)} alt='icon' />
                                    <FormControl className='max-widthform-selects mb-20'>
                                      <InputLabel id={`doc-type-label-${index}`} shrink={document.documentType !== ""}
                                        sx={{
                                          '&.Mui-focused': {
                                            color: document.documentType ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                          },
                                        }}
                                      >Select Document Type</InputLabel>
                                      <Select
                                        labelId={`doc-type-label-${index}`}
                                        id={`doc-type-${index}`}
                                        name="documentType"
                                        value={document.documentType || ""}
                                        onChange={(e) => {
                                          const selectedDocumentType = availableDocument?.find(docType => docType.name === e.target.value);
                                          const updatedDocuments = [...formData.documents];
                                          updatedDocuments[index].documentType = selectedDocumentType.name;
                                          updatedDocuments[index].type = selectedDocumentType.type;
                                          setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                          const updatedErrors = { ...docErrors };
                                          if (selectedDocumentType) {
                                            delete updatedErrors[`document-${index}-type`];
                                          } else {
                                            updatedErrors[`document-${index}-type`] = 'Document type is required';
                                          }

                                          setDocErrors(updatedErrors); // Update error state
                                          setFormData((prev) => ({ ...prev, documents: updatedDocuments })); // Update form data
                                        }}
                                        required
                                        label="Upload Document Type"
                                        error={!!docErrors[`document-${index}-type`]}>
                                        <MenuItem value="" disabled>Select Document Type</MenuItem>
                                        {availableDocumentTypes?.map((docType) => (
                                          <MenuItem key={docType._id} value={docType.name}>
                                            {docType.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {!!docErrors[`document-${index}-type`] && (
                                        <FormHelperText sx={{ color: 'red!important' }}>{docErrors[`document-${index}-type`]}</FormHelperText>
                                      )}
                                    </FormControl>
                                    {document.isUpload && (
                                      <>
                                        <FormControl className='max-widthform-selects' style={{
                                          border: "1px solid #554a8d", padding: "11px",
                                          borderRadius: "10px"
                                        }}>
                                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <label
                                              htmlFor={`file-upload-${index}`}
                                              style={{
                                                padding: "1px 11px",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "rgb(255 255 255)",
                                                color: "#000",
                                                cursor: "pointer",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Choose File
                                            </label>
                                            <span style={{ color: "#fff", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px" }}>
                                              {document.documentFile ? document.documentFile.name : "No file chosen"}
                                            </span>
                                            <input
                                              id={`file-upload-${index}`}
                                              type="file"
                                              name="documentFile"
                                              accept=".pdf"
                                              onChange={(e) => handleFileChange(e, index)}
                                              style={{ display: "none" }}
                                            />
                                          </div>
                                        </FormControl>
                                        {!!docErrors[`document-${index}-file`] && (
                                          <span style={{ color: "red", fontSize: "12px", marginLeft: "-61px", display: "block" }}>
                                            {docErrors[`document-${index}-file`]}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='line-top'></div>
                            </>
                          );
                        })}
                        <div style={{ margin: "15px 25px 0px auto", display: "flex", width: "fit-content", alignItems: "center" }}>
                          {formData.documents.length < availableDocument.length && <Button
                            variant="contained"
                            color="primary"
                            className="add-another-btn-doc marin-right-another-button"
                            onClick={() => {
                              const updatedDocuments = [...formData.documents, { documentType: '', isUpload: true, documentFile: null, type: '' }];
                              setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                            }}
                          >
                            Add Another Document
                          </Button>
                          }
                          <Button variant="contained" color="primary" className="add-another-btn-doc save-in" onClick={() => handleBack()}>Back</Button>
                        </div>
                      </>
                    )}

                  </div>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="add-another-btn-doc save-in"
                  onClick={() => handleSave()}
                  style={{ width: "175px" }}
                >
                  Add Vendor
                  {loading && roleId == 1 ? (
                    <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
                  ) : null}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </form>
    </>
  )
}

export default AddNewVendorByClient