import React from "react";
import { Box, Grid } from "@mui/material";
import "./css/heatmap.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";

const Heatmap = ({ data }) => {
  const navigate = useNavigate(); 
  const handleClick = ((e) => {
    const vendors = [] = e?.vendors || [];
    if(vendors && vendors.length && vendors.length>0){
      navigate("/dashboard/vendors" ,{ state : {vendors}})
    }
  })
  return (
    <Box className="heatmap-container">
      <Grid container spacing={0} className="contain-box">
        {/* Row 1 */}
        <span className="critical">Critical</span>
        <Grid item xs={3} onClick={() => { handleClick(data["critical-low"]) }} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["critical-low"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["critical-medium"]) }} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["critical-medium"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["critical-high"]) }} className="critical-shadow-background">
          <Box className={`heatmap-box heatmap-box-critical`}>Critical <span>{data["critical-high"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["critical-critical"]) }} className="critical-shadow-background">
          <Box className={`heatmap-box heatmap-box-critical`}>Critical <span>{data["critical-critical"]?.score}</span></Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="contain-box">
        {/* Row 2 */}
        <span className="critical">High</span>
        <Grid item xs={3} onClick={() => { handleClick(data["high-low"]) }} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["high-low"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["high-medium"]) }} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["high-medium"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["high-high"]) }} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["high-high"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["high-critical"]) }} className="critical-shadow-background">
          <Box className={`heatmap-box heatmap-box-critical`}>Critical <span>{data["high-critical"]?.score}</span></Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="contain-box">
        {/* Row 3 */}
        <span className="critical">Medium</span>
        <Grid item xs={3} onClick={() => { handleClick(data["medium-low"]) }} className="green-shadow-background">
          <Box className={`heatmap-box heatmap-box-low`}>Low <span>{data["medium-low"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["medium-medium"]) }} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["medium-medium"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["medium-high"]) }} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["medium-high"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["medium-critical"]) }} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["medium-critical"]?.score}</span></Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="contain-box">
        {/* Row 4 */}
        <span className="critical">Low</span>
        <Grid item xs={3} onClick={() => { handleClick(data["low-low"]) }} className="green-shadow-background">
          <Box className={`heatmap-box heatmap-box-low`}>Low <span>{data["low-low"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["low-medium"]) }} className="green-shadow-background">
          <Box className={`heatmap-box heatmap-box-low`}>Low <span>{data["low-medium"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["low-high"]) }} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["low-high"]?.score}</span></Box>
        </Grid>
        <Grid item xs={3} onClick={() => { handleClick(data["low-critical"]) }} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["low-critical"]?.score}</span></Box>
        </Grid>
      </Grid>
      <div className="container-flex-low-high">
        <span>Low</span>
        <span>Medium</span>
        <span>High</span>
        <span>Critical</span>
      </div>
    </Box>
  );
};

export default Heatmap;