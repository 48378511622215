import { Box, Card, FormControl, MenuItem, Select } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState } from "react";
import { CustomNoRowsOverlay } from "../Assessment.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function ClientRemediationTable({ remediationListData , loader }) {
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });
    const handlePaginationModelChange = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };


    const columns = [
        {
            field: "id",
            headerName: "#",
            flex: 0.5,
            className: "serial-number",
            disableColumnMenu: true,
        },
        {
            field: "uniqueId",
            headerName: "ID",
            flex: 1,
            minWidth: 170,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1.2,
            minWidth: 125,
            disableColumnMenu: true,
        },
        {
            field: "severity",
            headerName: "Severity",
            flex: 1,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (params) => {
                const severity = params.value?.toLowerCase();
                let scoreTag = "-";
                if (severity === 'low') {
                    scoreTag = "Low";
                } else if (severity === 'medium') {
                    scoreTag = "Medium";
                } else if (severity === 'critical') {
                    scoreTag = "Critical";
                } else if (severity === 'high') {
                    scoreTag = "High";
                }
                //   if (!severity) return "Loading...";
                if (!severity) return "-";
                return (
                    scoreTag ? (
                        <span className={`tagClassapproval blank-white ${scoreTag}`}>
                            <span>{scoreTag}</span>
                        </span>
                    ) : (
                        "-"
                    )
                );
            },
        },
        {
            field: "startDate",
            headerName: "Date",
            flex: 1,
            minWidth: 100,
            disableColumnMenu: true,
            valueFormatter: (params) => {
                return moment.unix(params.value).format("DD MMM YYYY");
            }
        },
        {
            field: "isPending",
            headerName: "Status",
            aling: "right",
            headerAlign: "center",
            flex: 0.8,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (params) => {
                let status = params.value;
                return (
                    <div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
                        {status === true ?
                            <span className={`statusClass ${status}`} style={{ "minWidth": "120px"}}>{'Pending'}</span>
                            :
                            <span className={`statusClass Onboarding`} style={{ "minWidth": "120px"}} >{'Done'}</span>
                        }
                    </div>
                )
            },
        }
    ];

    const handleRowClick = (params) => {
        // console.log("params", params.row)
        navigate(`/dashboard/remediation-details`, { state: { Data: params.row } })
    };

    return (
        <>
            <Card className="full-coverdesign margin-questionnaire">
                <div className="cover-table">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            top: "4px",
                            left: "5px",

                        }}
                    >
                        <FormControl
                            className="select-entries"
                            variant="outlined"
                            size="small"
                        >
                            <Select
                                value={paginationModel.pageSize}
                                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                        <span className="entries-per">entries per page</span>{" "}
                    </div>
                    <div className="issues-tab-table">
                        <Box
                            sx={{
                                width: "100%",
                                "& .MuiDataGrid-columnHeaders": {
                                    minWidth: "100%",
                                    overflowX: "hidden",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    minWidth: "100%",
                                    overflowX: "auto",
                                },
                            }}
                        >

                            <DataGrid
                                rows={remediationListData}
                                columns={columns}
                                loading={loader}
                                rowHeight={70}
                                onPaginationModelChange={handlePaginationModelChange}
                                paginationModel={paginationModel}
                                components={{
                                    NoRowsOverlay: () => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                textAlign: "center",
                                                fontSize: "18px",
                                            }}
                                        >
                                            No data
                                        </Box>
                                    ),
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                    filter: {
                                        filterModel: {
                                            items: [],
                                            quickFilterValues: [],
                                        },
                                    },
                                }}
                                autosizeOptions={{
                                    columns: ["id", "firstName", "lastName"],
                                    includeOutliers: true,
                                    includeHeaders: false,
                                }}
                                pageSizeOptions={[5, 10, 25]}
                                slots={{
                                    toolbar: GridToolbar,
                                    noRowsOverlay: CustomNoRowsOverlay,
                                }}
                                disableRowSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                disableColumnMenu
                                paginationMode="client"
                                disableSelectionOnClick
                                autoHeight
                                className="datagrid-table click-hover-datagrid"
                                sx={{
                                    "& .MuiDataGrid-columnHeaders": {
                                        minWidth: "100%", // Column headers take up full width
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        minWidth: "100%", // Rows take up full width
                                    },
                                }}
                                onRowClick={handleRowClick}
                            />
                        </Box>
                    </div>
                </div>
            </Card >
        </>
    );
}

export default ClientRemediationTable;