import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  TextField
} from "@mui/material";
import NewHeader from "../../layouts/dashboard/header/NewHeader";
import Breadcrumb from "../../pages/VendorDetail/Breadcrumb.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CrossImg from "../../assets/cross-select.svg";
import { useSnackbar } from "notistack";
import GetRequest from "../../components/apiConnections/getRequest.js";
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import moment from "moment";
import PostRequest from "../../components/apiConnections/postRequest.js";
import { useLocation } from "react-router-dom";
const style = {
  position: "absolute",
  background: '#120A3A',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  boxShadow: 24,
  maxHeight: "586px", // Set maximum height for scrolling
  overflowY: "auto",  // Enable vertical scrolling
};
function VulnerabilityManagementIssueDetail() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  let clientId = localStorage.getItem("userId")
  const [contactPersonList, setContactPersonList] = useState([]);
  const [openCreateRemediation, setOpenCreateRemediation] = React.useState(false);
  const handleOpenCreateRemediation = (key) => {
    getVendorContactPerson(key?.domain);
    setFormValues({
      issueId: selectedIssue?.issueId || '-',
      name: selectedIssue?.issueName || "",
      description: selectedIssue?.description || "",
      url: (key?.url && key?.url?.length) ? key?.url?.join('\r\n') || "" : "",
      remediation: selectedIssue?.remediation || "",
      deadline: "",
      remark: "",
      severity: selectedIssue.severity
    });
    setOpenCreateRemediation(true);

  }
  const handleCloseCreateRemediation = () => {
    setOpenCreateRemediation(false);
    setSelectedContactPerson("");
    setFormValues({});
  }
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [activeTab, setActiveTab] = useState(null);
  const [selectedIssue, setSelectedIssue] = useState(location?.state?.issueData)
  const [loading, setLoading] = useState(false);
  const [issueData, setIssueData] = useState(location?.state?.issueData);
  const [issueID, setIssueID] = useState(location?.state?.issueData?.issueId);
  const [remediationStaus, setRemediationStaus] = useState([]);
  const [formValues, setFormValues] = useState({
    issueId: selectedIssue?.issueId || '',
    name: selectedIssue?.IssueName || "",
    description: selectedIssue?.Description || "",
    // url: selectedIssue?.references ? selectedIssue?.references : selectedIssue?.ele?.Source,
    remediation: selectedIssue?.Remediation || "",
    deadline: "",
    remark: ""
  });

  const breadcrumbs = [
    { label: "Issue", path: "/dashboard/vulnerability",  mainTabValue: "1"  },
    { label: "Vulnerability Detail" },
  ];
  const toggleTab = (tabIndex) => {
    setActiveTab((prevTab) => (prevTab === tabIndex ? null : tabIndex));
  };

  useEffect(() => {
    getRemediationStatus();
  }, []);

  const getVendorContactPerson = async (domain) => {
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${domain}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setContactPersonList(obj2);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Contact person not found", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Contact person not found", { variant: "error" });
      return false;
    }
  };

  const sendRemediation = (event) => {
    event.preventDefault();
    const contactPerson = JSON.parse(selectedContactPerson || "{}");
    if (!validateForm(formValues, contactPerson)) {
      return;  // Stop execution if validation fails
    }
    setLoading(true);
    const payload = {
      uniqueId: uniqueIdGenerator(contactPerson),
      firstName: contactPerson?.firstName,
      lastName: contactPerson?.lastName,
      email: contactPerson?.email,
      deadline: formValues.deadline,
      createdBy: localStorage.getItem("userEmail"),
      url: contactPerson?.vendorDomain,
      remediationType: "forIssue",
      issueId: formValues?.issueId,
      issueRemediationPlan: {
        description: formValues.description,
        issueName: formValues.name,
        remark: formValues.remark,
        url: formValues?.url?.split('\n').join(','),
        severity: formValues.severity
      },
      severity: formValues.severity
    }
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_REMEDIATION_BY_USER}`;
    PostRequest(url, payload)
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        setLoading(false);
        getRemediationStatus();
        handleCloseCreateRemediation();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      });
  }

  const handleContactPersonChange = (e) => {
    setSelectedContactPerson(e.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = (formValues, contactPerson) => {
    if (!formValues.deadline || formValues.deadline.trim() === "") {
      enqueueSnackbar("Please select deadline.", { variant: "error" });
      return false;
    }
    if (!contactPerson?.email || contactPerson?.email.trim() === "") {
      enqueueSnackbar("Please select a valid contact person.", { variant: "error" });
      return false;
    }
    if (!formValues.description || formValues.description.trim() === "") {
      enqueueSnackbar("Please provide a valid description.", { variant: "error" });
      return false;
    }
    if (!formValues.name || formValues.name.trim() === "") {
      enqueueSnackbar("Please provide a valid issue name.", { variant: "error" });
      return false;
    }
    if (!formValues.remark || formValues.remark.trim() === "") {
      enqueueSnackbar("Please provide a valid remark.", { variant: "error" });
      return false;
    }
    if (!formValues.url || Array.isArray(formValues.url) || formValues.url.length === 0) {
      enqueueSnackbar("Please provide a valid URL list.", { variant: "error" });
      return false;
    }
    return true;
  };

  const getCurrentDate = (date) => {
    let parseDate = moment(date).add(1, 'day');
    let currentDate = new Date(parseDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const uniqueIdGenerator = (contactPerson) => {
    function generateSixDigitAlphanumeric() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#';
      let result = '';
      for (let i = 0; i < 6; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    }
    let alphaNumericId = generateSixDigitAlphanumeric();
    let uniqueId = `REM-${alphaNumericId}`;
    return uniqueId;
  }
  const getRemediationStatus = () => {
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_REMEDIATION_STATUS}?clientId=${clientId}&issueId=${issueID}`
      )
        .then((res) => {
          const obj2 = res.data?.data;
          setRemediationStaus(obj2)
        })
        .catch((err) => {
          return false;
        });
    } catch (error) {
      return false;
    }
  };
  const getStatus = (vendorName) => {
    const matchedVendor = remediationStaus?.find((item) => item.url === vendorName);
    return matchedVendor ? matchedVendor?.isPending : null;
  };

  return (
    <>
      <Helmet>
        <title> Vulnerability | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Vulnerability Issue Detail" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className="vulnerability-flex-detail">
              <div>
                <h3>Name</h3>
                <h4>{issueData?.issueName || "-"}</h4>
              </div>
              <div>
                <h3>Severity</h3>
                <h4>
                  <span className={`tagClass ${issueData?.severity}`}>{issueData?.severity || "-"}</span>
                </h4>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className="bg-dark-detail">
              <div className="discriptions-vulnerability-text">
                <h3>Description</h3>
                <p>{issueData?.description || "-"}</p>
              </div>
              <div className="discriptions-vulnerability-text">
                <h3>Remediation</h3>
                <p>{issueData?.remediation || "-"}</p>
              </div>
              <div className="discriptions-vulnerability-text">
                <h3>References</h3>
                {issueData?.references?.map((key, i) => (
                  <p key={i}> # {i + 1}
                    <a style={{ color: "white", marginLeft: "15px" }}
                      href={key.startsWith("http://") || key.startsWith("http://") ? key : `http://${key}`}
                      target="_blank"
                      rel="noopener noreferrer"> {key}</a>
                  </p>
                ))}

              </div>
              {issueData?.domains?.map((key, i) => (
                <div key={key.domain} className="accordion-item">
                  <h4 onClick={() => toggleTab(i)} className="accordion-header issues-related-detail-line">
                    <div style={{ width: '50%' }} className="commet-line" key={key.domain} >
                      <span>{key?.domain.split('.')[0].replace(/\b\w/g, (char) => char.toUpperCase())}</span></div>
                    <div className="commet-line" key={i} >
                      <span>Status:</span>
                      <span
                        className={`${getStatus(key?.domain) === true ? "Pending" : getStatus(key?.domain) === false ? "Fixed" : 'ToSent'}`}
                        key={i}
                      >
                        {getStatus(key?.domain) === true ? "Pending" : getStatus(key?.domain) === false ? "Fixed" : '-'}
                      </span>
                      <span className="btncreate-remediation"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedIssue(issueData)

                          handleOpenCreateRemediation(key);
                        }}
                      > + Create Remediation </span></div>
                    <div className="accordion-icon">
                      {activeTab === i ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </div>
                  </h4>
                  {activeTab === i && (
                    <div className="accordion-content">
                      <div className="discriptions-vulnerability-text">
                        {key?.url.length ?
                          <>{key?.url?.map((key1, i) => (
                            <p key={i}> # {i + 1}
                              <a style={{ color: "white", marginLeft: "15px" }}
                                href={key1.startsWith("http://") || key1.startsWith("http://") ? key1 : `http://${key1}`}
                                target="_blank"
                                rel="noopener noreferrer"> {key1}</a>
                            </p>
                          ))}</>
                          :
                          <>{key?.Source?.map((key1, i) => (
                            <p key={i}> # {i + 1}
                              <a style={{ color: "white", marginLeft: "15px" }}
                                href={key1.startsWith("http://") || key1.startsWith("http://") ? key1 : `http://${key1}`}
                                target="_blank"
                                rel="noopener noreferrer"> {key1}</a>
                            </p>
                          ))}</>
                        }
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
      {selectedIssue && <Modal
        open={openCreateRemediation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="scroll-design">
          <div className="popup-remiadation">
            <div className="pop-flex-remiadation">
              <h3>Create Remediation</h3>
              <img src={CrossImg} style={{ cursor: 'pointer' }} alt="icon" onClick={handleCloseCreateRemediation} />
            </div>
            <form onSubmit={sendRemediation} fullwidth={true} sx={{ mb: 3 }}>
              <div className="popup-form-remidaition">
                <FormControl fullwidth>
                  <InputLabel id="demo-simple-select-label">
                    {!selectedContactPerson ? "Select Person" : ""}
                  </InputLabel>
                  <TextField
                    labelid="demo-simple-select-label"
                    select
                    defaultValue=""
                    value={selectedContactPerson || ""}
                    onChange={handleContactPersonChange}
                    sx={{ mb: 3 }}
                  >
                    {contactPersonList && contactPersonList.length >= 0 && contactPersonList.map((item) => (
                      <MenuItem key={item.email} value={JSON.stringify(item)}>
                        {item.firstName} {item.lastName} {`(${item.email})`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
                <TextField
                  name="name"
                  label="Issue Name"
                  fullwidth
                  value={formValues.name}
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label="Description"
                  name="Description"
                  multiline
                  fullwidth
                  rows={3}
                  // maxRows={4}
                  sx={{ mb: 3 }}
                  value={formValues.description}
                  onChange={handleChange}
                />
                <TextField
                  label="Affected URLs"
                  name="URL"
                  fullwidth
                  multiline
                  rows={3}
                  sx={{ mb: 3 }}
                  value={formValues.url}
                  onChange={handleChange}
                />
                <TextField
                  label="Remediation Steps"
                  name="Remediation"
                  multiline
                  fullwidth
                  rows={3}
                  // maxRows={4}
                  sx={{ mb: 3 }}
                  value={formValues.remediation}
                  onChange={handleChange}
                />
                <TextField
                  name="deadline"
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                  label="Deadline"
                  className="deadline-input"
                  onChange={handleChange}
                  value={formValues.deadline}
                  inputProps={{
                    min: getCurrentDate(new Date()),
                    onKeyDown: (e) => e.preventDefault(),
                    style: {
                      WebkitAppearance: 'none', // Remove on Chrome/Safari/Edge
                      MozAppearance: 'textfield', // Remove on Firefox
                      appearance: 'none', // General appearance removal
                      position: 'relative',
                    },
                  }}
                  sx={{ color: "white", mb: 3 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* {/ Custom image for opening the date picker /} */}
                        <img
                          src={CalendarTodayIcon}
                          alt="calendar icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            document.getElementsByName('deadline')[0].showPicker();
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Remark"
                  name="remark"
                  multiline
                  fullwidth
                  rows={3}
                  // maxRows={4}
                  value={formValues.remark}
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
                <div className="button-alin-remaidation">
                  <Button onClick={handleCloseCreateRemediation} variant="contained" className="cancel-popup-btns">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" className="cancel-popup-btns save-direct">
                    Save
                    {loading ? (
                      <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
                    ) : null}
                  </Button>

                </div>

              </div>
            </form>
          </div>
        </Box>
      </Modal>}
    </>
  );
}

export default VulnerabilityManagementIssueDetail;
