import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
// @mui
import { useTheme } from "@mui/material/styles";
import {
    TextField, InputAdornment,
    Container,
    Card,
    Box,
    Modal,
} from "@mui/material";
import SearchIcon from '../../assets/searchico.svg';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import "../../pages/css/Vendors.css";
import "../../pages/css/newVendor.css";
import FormControl from "@mui/material/FormControl";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../Assessment.js"
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import PostRequest from "../../components/apiConnections/postRequest.js";
import Markdown from "marked-react";
// ----------------------------------------------------------------------
export default function RemediationForGaps() {
    const navigate = useNavigate()
    const theme = useTheme();
    const [remediationListData, setRemediationListData] = useState();
    const userEmail = window.localStorage.getItem("userEmail");
    const [modelOpen, setModelOpen] = useState(null);
    const [loader, setshowLoader] = useState(false);
    const [pageSize, setPageSize] = useState(5);
    const [rowCount, setRowCount] = useState(0);
    const [filterSearch, setFilterSearch] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        bgcolor: "solid #0000",
        border: "2px solid #000",
        boxShadow: 24,
        borderRadius: "15px",
        p: 4,
        height: "80vh",
        overflowY: "auto",
    };

    useEffect(() => {
        getRemediationByVendor(paginationModel.page + 1, paginationModel.pageSize);
    }, [paginationModel]);

    const handleSearch = (event) => {
        setFilterSearch(event.target.value)
    };

    const getRemediationByVendor = async (page, pageSize) => {
        setshowLoader(true);
        const type = "forGaps"
        try {
            const remediationPayload = {
                email: userEmail,
            };
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_REMEDIATION_PLAN_BY_VENDOR}?page=${page
                    }&pageSize=${pageSize}&remediationType=${type}&search=${filterSearch || ""}`,
                    remediationPayload
                )
                    .then((resRemediation) => {
                        const obj = resRemediation?.data?.data?.data;
                        setRemediationListData(obj);
                        setRowCount(resRemediation?.data?.data?.totalRemediation || 0);
                        setshowLoader(false);
                    })
                    .catch((err) => {
                        setshowLoader(false);
                        return false;
                    });
            }
        } catch (error) {
            setshowLoader(false);
            return false;
        }
    };

    const handlePaginationModelChange = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };
    const handleRowClick = (params) => {
        navigate(`/vendor/remediation-details`, { state: { Data: params.row, panel: 'vendor' } })
    };
    const filteredRows =
        (!remediationListData || !remediationListData.length) ? [] :
            remediationListData
                ?.map((val, i) => ({
                    ...val,
                    id: (paginationModel.page * paginationModel.pageSize)+ i + 1,
                    _id: val.id,
                    name: `${val?.firstName} ${val?.lasttName}`,
                    uniqueId: val.uniqueId || "--",
                    isPending: val?.isPending
                }));

    const columns = [
        {
            field: "id",
            headerName: "#",
            flex: 0.5,
            className: "serial-number",
            disableColumnMenu: true,
        },
        {
            field: "uniqueId",
            headerName: "ID",
            flex: 1.1,
            minWidth: 170,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 125,
            disableColumnMenu: true,
        },
        {
            field: "severity",
            headerName: "Severity",
            flex: 1,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (params) => {
                const severity = params.value?.toLowerCase();
                let scoreTag = "-";
                if (severity === 'low') {
                    scoreTag = "Low";
                } else if (severity === 'medium') {
                    scoreTag = "Medium";
                } else if (severity === 'critical') {
                    scoreTag = "Critical";
                } else if (severity === 'high') {
                    scoreTag = "High";
                }
                if (!severity) return "-";
                return (
                    scoreTag ? (
                        <span className={`tagClassapproval blank-white ${scoreTag}`}>
                            <span>{scoreTag}</span>
                        </span>
                    ) : (
                        "-"
                    )
                );
            },
        },
        {
            field: "startDate",
            headerName: "Date",
            flex: 1,
            minWidth: 100,
            disableColumnMenu: true,
            valueFormatter: (params) => {
                return moment.unix(params.value).format("DD MMM YYYY");
            }
        },
        {
            field: "isPending",
            headerName: "Status",
            flex: 1,
            minWidth: 100,
            headerAlign: "left",
            align: "left",
            disableColumnMenu: true,
            renderCell: (params) => {
                let status = params.value;
                return (
                    <div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
                        {status === true ?
                            <span className={`statusClass ${status}`}>{'Pending'}</span>
                            :
                            <span className={`statusClass Onboarding`}>{'Done'}</span>
                        }
                    </div>
                )
            },
        }, {
            field: "viewRemedation",
            headerName: "",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.remediationPlan ? (
                            <span onClick={(e) => {
                                e.stopPropagation();
                                setModelOpen(params?.row)
                            }}
                                style={{ fontSize: "13px", width: "97%", padding: "15px 6px" }}
                                className="create-remediation-button">
                                {" "}
                                View Remediation
                            </span>
                        ) : <span className="no-remediation-button" style={{
                            backgroundColor: "gray"
                        }}>
                            --
                        </span>}
                    </>
                );
            },
        }
    ];

    useEffect(() => {
        let time = setTimeout(() => {
            if (filterSearch !== null) getRemediationByVendor(1, paginationModel.pageSize)
        }, 500)
        return () => clearTimeout(time)
    }, [filterSearch])
    return (
        <>
            <Container maxWidth={false} style={{ padding: "0% 0% 0% 0%" }}>
                <Card className="full-coverdesign margin-questionnaire">
                    <div className="cover-table">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                                top: "4px",
                                left: "5px",

                            }}
                        >
                            <FormControl
                                className="select-entries"
                                variant="outlined"
                                size="small"
                            >
                                <Select
                                    value={paginationModel.pageSize}
                                    onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                            <span className="entries-per">entries per page</span>{" "}
                        </div>
                        <div className="issues-tab-table top-0issues">
                            <Box
                                sx={{
                                    width: "100%",
                                    "& .MuiDataGrid-columnHeaders": {
                                        minWidth: "100%",
                                        overflowX: "hidden",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        minWidth: "100%",
                                        overflowX: "auto",
                                    },
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    placeholder="Search…"
                                    value={filterSearch}
                                    onChange={handleSearch}
                                    className='searh-text-field topsearch-top-0'
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt='icon' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <DataGrid
                                    loading={loader}
                                    rows={filteredRows}
                                    columns={columns}
                                    rowHeight={70}
                                    onPaginationModelChange={handlePaginationModelChange}
                                    paginationModel={paginationModel}
                                    rowCount={rowCount}
                                    components={{
                                        NoRowsOverlay: () => (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    textAlign: "center",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                No data
                                            </Box>
                                        ),
                                    }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                        filter: {
                                            filterModel: {
                                                items: [],
                                                quickFilterValues: [],
                                            },
                                        },
                                    }}
                                    autosizeOptions={{
                                        columns: ["id", "firstName", "lastName"],
                                        includeOutliers: true,
                                        includeHeaders: false,
                                    }}
                                    pageSizeOptions={[5, 10, 25, 50]}
                                    slots={{
                                        toolbar: GridToolbar,
                                        noRowsOverlay: CustomNoRowsOverlay,
                                    }}
                                    disableRowSelectionOnClick
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableDensitySelector
                                    disableColumnMenu
                                    paginationMode="server"
                                    disableSelectionOnClick
                                    autoHeight
                                    className="datagrid-table"
                                    sx={{
                                        "& .MuiDataGrid-columnHeaders": {
                                            minWidth: "100%", // Column headers take up full width
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            minWidth: "100%", // Rows take up full width
                                        },
                                    }}
                                    onRowClick={handleRowClick}
                                />
                            </Box>
                        </div>
                    </div>
                </Card>
                <Modal
                    open={modelOpen !== null}
                    onClose={() => setModelOpen(null)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={
                        theme === "Dark"
                            ? "dark-attack-modal modal-popup"
                            : "light-attack-modal modal-popup"
                    }
                >
                    <Box sx={style} className="black-bg openai-response popup-remiadation scroll-design">
                        <CloseIcon
                            onClick={() => setModelOpen(null)}
                            style={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                cursor: "pointer",
                            }}
                        />
                        <div className="fialed-control-poup">
                            <h3 className="relation" style={{ color: "#fff" }}>
                                Remediation
                            </h3>
                            {/* <div className='flex-preview-tab'>
                                <p className='preview-question-heading'>Q.  </p>
                                <span className='data-text-preview'>{modelOpen?.que?.text ? modelOpen?.que?.text : 'N/A'}</span>
                            </div> */}
                            <div>
                                <ul>
                                    
                                    <li>
                                        <span className="span-font-size">
                                        <Markdown>{modelOpen?.remediationPlan}</Markdown></span>
                                    </li>
                                    {/* <li>
                                        <h4>Remark: </h4>
                                        <span className="span-font-size">{modelOpen?.remark}</span>
                                    </li> */}
                                    <li>
                                        <h4>URL:</h4>
                                        <span className="span-font-size">{modelOpen?.url && modelOpen.url.split('\r,').map((url, index) => (
                                            <span key={index} className="span-font-size">{url}</span>
                                        ))}</span>
                                    </li>

                                    <li>
                                        <h4>severity:</h4>
                                        <span className="span-font-size">{modelOpen?.severity}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Box>

                </Modal>
            </Container >
        </>
    );
}