import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import unreadMessagesReducer from "./unreadMessagesSlice";
import activeTabReducer from "./activeTabSlice";
import permissionListReducer from './permissionListSlice';
import subscriptionDetailsReducer from './subscriptionDetailsSlice';
import vendorDetailsReducer from './vendorDetailsSlice'
const store = configureStore({
  reducer: {
    theme: themeReducer,
    unreadMessages: unreadMessagesReducer,
    activeTab: activeTabReducer,
    permissionList: permissionListReducer,
    subscriptionDetails: subscriptionDetailsReducer, 
    vendorDetails: vendorDetailsReducer
  },
});

export default store;