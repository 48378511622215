import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vendor: {},
};

const vendorDetailsSlice = createSlice({
    name: "vendorDetails",
    initialState,
    reducers: {
        addVendorDetails: (state, action) => {
            state.vendorDetails = action.payload;
        },
    },
});

export const { addVendorDetails } = vendorDetailsSlice.actions;

export default vendorDetailsSlice.reducer;